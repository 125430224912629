import { createTheme, Theme } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import reliveItTheme, { createCustomPaletteColor } from "theme/reliveItTheme";

import RoutesContainer from "components/RoutesContainer/RoutesContainer";
import DashboardLayout from "components/Dashboard/DashboardLayout";
import AuthWindow from "Windows/Auth/AuthWindow";

import { SnackbarProvider } from "context/Snackbar/SnackbarContext";
import useAccess from "hooks/useAccess";
import { AuthProvider, useAuth } from "context/Auth/AuthContext";

import { initTracker } from "hooks/eventTracker/useEventTracker";
import GlobalSnackbar from "components/Snackbar/GlobalSnackbar";
import { useLocation } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { getFirebaseController } from "database/FirebaseController";
import { useAtomValue } from "jotai";

import { themeSettingsState } from "states/layout";
import _ from "lodash";

initTracker();

const Content = () => {
  const { isLoading, currentUser } = useAuth();
  const { isAdmin } = useAccess();

  return (
    <>
      {!isLoading && (
        <>
          {currentUser ? (
            <DashboardLayout>
              <RoutesContainer isAdmin={isAdmin || false} />
            </DashboardLayout>
          ) : (
            <AuthWindow />
          )}
        </>
      )}
    </>
  );
};

const App = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // init when app start
    getFirebaseController();
    // getDropboxController().setupToken();
  }, []);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement!.scrollTop = 0;
    document.scrollingElement!.scrollTop = 0;
  }, [pathname]);

  const themeSettings = useAtomValue(themeSettingsState);

  const palette = useMemo(() => {
    const palette = _.cloneDeep(reliveItTheme.palette) as Theme["palette"];

    const isCustom = true;

    if (isCustom && themeSettings && palette) {
      palette.primary.main = themeSettings.primaryColor || palette.primary.main;

      palette.secondary.main =
        themeSettings.secondaryColor || palette.secondary.main;

      palette.tertiary =
        createCustomPaletteColor(themeSettings.tertiaryColor) ||
        palette.tertiary;

      palette.background.default =
        themeSettings.backgroundColor || palette.background.default;

      palette.background.paper =
        themeSettings.surfaceColor || palette.background.paper;

      palette.surface = themeSettings.surfaceColor
        ? createCustomPaletteColor(themeSettings.surfaceColor)
        : palette.surface;

      palette.info.main = themeSettings.infoColor || palette.info.main;

      palette.success.main = themeSettings.successColor || palette.success.main;

      palette.warning.main = themeSettings.warningColor || palette.warning.main;

      palette.error.main = themeSettings.errorColor || palette.error.main;

      palette.onPrimary = themeSettings.onPrimaryColor
        ? createCustomPaletteColor(themeSettings.onPrimaryColor)
        : palette.onPrimary;

      palette.onSecondary = themeSettings.onSecondaryColor
        ? createCustomPaletteColor(themeSettings.onSecondaryColor)
        : palette.onSecondary;

      palette.onTertiary = themeSettings.onTertiaryColor
        ? createCustomPaletteColor(themeSettings.onTertiaryColor)
        : palette.onTertiary;

      palette.onSurface = themeSettings.onSurfaceColor
        ? createCustomPaletteColor(themeSettings.onSurfaceColor)
        : palette.onSurface;

      palette.onBackground = themeSettings.onBackgroundColor
        ? createCustomPaletteColor(themeSettings.onBackgroundColor)
        : palette.onBackground;
    }

    return palette;
  }, [themeSettings]);

  const theme = {
    ...reliveItTheme,
    palette: {
      ...reliveItTheme.palette,
      ...palette,
    },
  };

  return (
    <SnackbarProvider>
      <AuthProvider>
        <ThemeProvider theme={createTheme(theme)}>
          <CssBaseline />

          <Content />

          <GlobalSnackbar />
        </ThemeProvider>
      </AuthProvider>
    </SnackbarProvider>
  );
};

export default App;
