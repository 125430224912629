import {
  RefObject,
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";

import { useAtomValue } from "jotai";
import { searchState } from "states/navbar";

import useLocalStorage from "hooks/useLocalStorage";

import {
  Box,
  Card,
  Grid,
  Icon,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  alpha,
  darken,
  lighten,
} from "@mui/material";

import Battery4BarIcon from "@mui/icons-material/Battery4Bar";
import SolarPowerIcon from "@mui/icons-material/SolarPower";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CameraIcon from "@mui/icons-material/Camera";
import BrokenImageIcon from "@mui/icons-material/BrokenImage";

import {
  DeviceV2,
  DeviceV2Details,
  FirebaseImage,
  IntervalV2,
} from "database/DataTypes";
import { getFirebaseController } from "database/FirebaseController";

import LDSRingLoader from "components/Loaders/LDSRingLoader";
import IntervalIcon from "components/Icons/IntervalIcon";
import DashboardContainer from "components/Dashboard/DashboardContainer";
import DashboardHeader, {
  SortType,
} from "components/Dashboard/DashboardHeader";
import { Column } from "components/Dashboard/SettingsTable";
import { getPhotoTakenCounts } from "./DeviceStatusWindow";

import moment from "moment";

import _ from "lodash";
import useTimeZone, { momentWithTimeZoneHOF } from "hooks/useTimeZone";
import { useDashboardTracker } from "hooks/eventTracker/useDashboardTracker";
import DashboardListTable from "components/Dashboard/DashboardListTable";
import { orderByIgnoreCase } from "utils/display";

const _thumbnailCaches = {};

const SORT_OPTIONS = [
  "Friendly Name",
  "Device Name",
  "Gallery Name",
  "Interval",
  "Battery",
  "Solar",
  "Capture",
  "Alert",
];

const WARNING_SOLAR_VOLTAGE_MIN = 10.0;
const WARNING_BATTERY_VOLTAGE_MIN = 12.9;

interface PhotoCountInfo {
  total: number;
  taken: number;
  uploaded: number;
}

const DeviceCard = ({
  device,
  deviceIndex,
  deviceInterval,
  devicePhotoCountInfo,
  deviceDetails,
  lastImage,
  isWarning,
  isError,
}: {
  device: DeviceV2;
  deviceIndex: number;
  deviceInterval: IntervalV2 | null;
  devicePhotoCountInfo: PhotoCountInfo;
  deviceDetails: DeviceV2Details;
  lastImage: FirebaseImage | null;
  isWarning: boolean;
  isError: boolean;
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);

  const [thumbnailSrc, setThumbnailSrc] = useState<string>("");

  const navigate = useNavigate();

  useEffect(() => {
    if (device) {
      initStates(device);
    }
  }, [device]);

  const initStates = async (device: DeviceV2) => {
    setIsLoading(true);

    getDeviceThumbnail(device)
      .then((src) => {
        setThumbnailSrc(src);
        _thumbnailCaches[device.id as number] = src;
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const getDeviceThumbnail = async (device: DeviceV2): Promise<string> => {
    if ((device.id as number) in _thumbnailCaches) {
      return _thumbnailCaches[device.id as number] || "";
    }

    if (lastImage) {
      return await getFirebaseController()
        .Image.getThumbnail(lastImage.storageLocation, lastImage.fileName)
        .then((resultURL) => {
          if (resultURL) {
            return resultURL;
          } else {
            return lastImage.url;
          }
        });
    } else {
      return "";
    }
  };

  const Loader = ({ isShow }) => {
    return (
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          visibility: isShow ? "visible" : "hidden",
        }}
      >
        <LDSRingLoader />
      </Box>
    );
  };

  const ThumbnailPlaceHolder = () => {
    return (
      <Box
        sx={{
          aspectRatio: 185 / 139,
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: ({ palette }) =>
            !isLoading && !thumbnailSrc
              ? alpha(palette.secondary.main, 0.3)
              : "none",
        }}
      >
        {isLoading || (thumbnailSrc && !isLoaded) ? (
          <Loader isShow={isLoading || (thumbnailSrc && !isLoaded)} />
        ) : (
          <>
            {!isLoading && !thumbnailSrc && (
              <BrokenImageIcon
                fontSize="large"
                sx={{ color: ({ palette }) => palette.onSurface.main }}
              />
            )}
          </>
        )}
      </Box>
    );
  };

  const deviceInfo = [
    {
      name: "battery",
      value: !isNaN(Number(device.battery))
        ? `${Number(device.battery).toFixed(2)} V`
        : "N/A",
      icon: <Battery4BarIcon sx={{ transform: "rotate(90deg)" }} />,
      description: "Device battery power (Volt)",
    },
    {
      name: "solar",
      value: !isNaN(Number(device.solar))
        ? `${Number(device.solar).toFixed(2)} V`
        : "N/A",
      icon: <SolarPowerIcon />,
      description: "Device solar power (Volt)",
    },
    {
      name: "taken",
      value: `${devicePhotoCountInfo?.taken || 0} / ${
        devicePhotoCountInfo?.total || 0
      }`,
      icon: <CameraIcon />,
      description: "Estimated photo taken count of the day",
    },
    {
      name: "uploaded",
      value: `${devicePhotoCountInfo?.uploaded || 0} / ${
        devicePhotoCountInfo?.total || 0
      }`,
      icon: <CloudUploadIcon />,
      description: "Estimated photo uploaded count of the day",
    },
  ];

  const timeInterval = deviceInterval?.timeBetweenShots;

  const { momentWithTimeZone } = useTimeZone(deviceDetails?.timeZone);

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Card
        sx={{
          backgroundColor: ({ palette }) => palette.tertiary.main,
          color: ({ palette }) => palette.onTertiary.main,

          width: "100",
          height: "100%",
          borderRadius: 1,
          transition: ({ transitions }) =>
            transitions.create("all", {
              duration: transitions.duration.shorter,
            }),
          boxShadow: 1,
          p: 1,

          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",

          ":hover": {
            transform: "scale(1.01)",
            boxShadow: 2,
            backgroundColor: ({ palette }) => alpha(palette.tertiary.main, 1),
          },
        }}
      >
        <Box>
          <Box
            sx={{
              flex: 1,
              cursor: "pointer",
              backgroundColor: ({ palette }) =>
                isError
                  ? alpha(palette.error.main, 0.5)
                  : isWarning
                  ? alpha(palette.warning.main, 0.5)
                  : palette.surface.main,

              color: ({ palette }) =>
                isError
                  ? palette.getContrastText(palette.error.main)
                  : isWarning
                  ? palette.getContrastText(palette.warning.main)
                  : palette.onSurface.main,

              borderRadius: 1,
              py: 1,
              px: 1,
              position: "relative",
            }}
            onClick={() => navigate(`/devices/${device.id}`)}
          >
            {!!timeInterval && (
              <Tooltip title="Time interval of the day">
                <Box
                  sx={{ position: "absolute", right: "8px", bottom: "16px" }}
                >
                  <IntervalIcon
                    amount={
                      timeInterval >= 60
                        ? _.round(timeInterval / 60)
                        : timeInterval
                    }
                    unit={timeInterval > 60 ? "Mins" : "Secs"}
                    color={({ palette }) =>
                      isError
                        ? palette.getContrastText(palette.error.main)
                        : isWarning
                        ? palette.getContrastText(palette.warning.main)
                        : palette.onSurface.main
                    }
                  />
                </Box>
              </Tooltip>
            )}

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    lineHeight: 1,
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                >
                  {device.friendlyName || device.deviceId}
                </Typography>

                <Typography
                  sx={{
                    lineHeight: 1,
                    fontSize: 14,
                    mt: 1,
                  }}
                >
                  {device.frontendName || "-"}
                </Typography>

                <Typography
                  sx={{
                    lineHeight: 1,
                    fontSize: 14,
                    mt: 0.5,
                  }}
                >
                  {device.deviceId || "-"}
                </Typography>

                <Tooltip
                  title={`Last contact time (${momentWithTimeZone(
                    device.lastReported,
                  ).format("z Z")})`}
                >
                  <Typography
                    sx={{
                      lineHeight: 1,
                      fontSize: 14,
                      mt: 1,
                    }}
                  >
                    <Box component={"span"}>
                      {device.lastReported
                        ? momentWithTimeZone(device.lastReported).format(
                            "DD-MM-YYYY",
                          )
                        : ""}
                    </Box>

                    <Box component={"span"} sx={{ ml: 1 }}>
                      {device.lastReported
                        ? momentWithTimeZone(device.lastReported).format(
                            "HH:mm a",
                          )
                        : ""}
                    </Box>
                  </Typography>
                </Tooltip>

                <Tooltip
                  title={`Last photo time (${momentWithTimeZone(
                    lastImage?.timestamp,
                  ).format("z Z")})`}
                >
                  <Typography
                    sx={{
                      fontSize: 14,
                    }}
                  >
                    <Box component={"span"}>
                      {lastImage
                        ? momentWithTimeZone(lastImage.timestamp).format(
                            "DD-MM-YYYY",
                          )
                        : ""}
                    </Box>

                    <Box component={"span"} sx={{ ml: 1 }}>
                      {lastImage
                        ? momentWithTimeZone(lastImage.timestamp).format(
                            "HH:mm a",
                          )
                        : ""}
                    </Box>
                  </Typography>
                </Tooltip>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            mt: 1,
            position: "relative",
            cursor: "pointer",
            aspectRatio: 185 / 139,
            borderRadius: 1,
            overflow: "hidden",
          }}
          onClick={() => navigate(`/devices/${device.id}/image-viewer`)}
        >
          <ThumbnailPlaceHolder />

          {thumbnailSrc && (
            <Box
              component={"img"}
              crossOrigin="anonymous"
              width={"100%"}
              onLoad={() => setIsLoaded(true)}
              src={thumbnailSrc}
              sx={{
                display: "block",
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          )}
        </Box>

        <Box
          sx={{ cursor: "pointer" }}
          onClick={() => navigate(`/devices/${device.id}`)}
        >
          <Grid
            container
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            {deviceInfo.map((info, infoIndex) => {
              return (
                <Grid key={infoIndex} item xs={3} p={1}>
                  <Tooltip title={info.description} placement="top">
                    <Grid
                      container
                      direction={"column"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      spacing={0.5}
                    >
                      <Grid item sx={{ display: "flex" }}>
                        {info.icon}
                      </Grid>

                      <Grid item>
                        <Typography
                          sx={{
                            textWrap: "nowrap",
                            lineHeight: 1,
                            fontSize: 14,
                          }}
                        >
                          {info.value}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Tooltip>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Card>
    </Grid>
  );
};

const GridView = ({
  displayDeviceList,
  intervalsLookup,
  photoCountInfoLookup,
  deviceDetailsLookup,
  lastImageLookup,
  isDeviceWarning,
  isDeviceError,
}: {
  displayDeviceList: DeviceV2[];
  intervalsLookup: { [key: number]: IntervalV2 };
  photoCountInfoLookup: { [key: number]: PhotoCountInfo };
  deviceDetailsLookup: { [key: number]: DeviceV2Details };
  lastImageLookup: { [key: number]: FirebaseImage };
  isDeviceWarning: (device: DeviceV2) => boolean;
  isDeviceError: (device: DeviceV2) => boolean;
}) => {
  const { trackAction, isTrackerLoaded } = useDashboardTracker("devices");

  useEffect(() => {
    if (isTrackerLoaded) {
      trackAction("view grid");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTrackerLoaded]);

  return (
    <Grid container spacing={1}>
      {displayDeviceList.length > 0 ? (
        <>
          {displayDeviceList.map((device, deviceIndex) => {
            return (
              <DeviceCard
                key={device.id}
                device={device}
                deviceIndex={deviceIndex}
                deviceInterval={intervalsLookup[device.id as number]}
                devicePhotoCountInfo={photoCountInfoLookup[device.id as number]}
                deviceDetails={deviceDetailsLookup[device.id as number]}
                lastImage={lastImageLookup[device.id as number]}
                isWarning={isDeviceWarning(device)}
                isError={isDeviceError(device)}
              />
            );
          })}
        </>
      ) : (
        <Grid item>
          <Typography variant="caption" textAlign={"center"}>
            No device found.
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

const ListView = ({
  displayDeviceList,
  intervalsLookup,
  photoCountInfoLookup,
  deviceDetailsLookup,
  lastImageLookup,
  headerRef,
  isDeviceWarning,
  isDeviceError,
  isLoading,
}: {
  displayDeviceList: DeviceV2[];
  intervalsLookup: { [key: number]: IntervalV2 };
  photoCountInfoLookup: { [key: number]: PhotoCountInfo };
  deviceDetailsLookup: { [key: number]: DeviceV2Details };
  lastImageLookup: { [key: number]: FirebaseImage };
  headerRef: RefObject<HTMLDivElement>;
  isDeviceWarning: (device: DeviceV2) => boolean;
  isDeviceError: (device: DeviceV2) => boolean;
  isLoading: boolean;
}) => {
  const { trackAction, isTrackerLoaded } = useDashboardTracker("devices");

  useEffect(() => {
    if (isTrackerLoaded) {
      trackAction("view list");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTrackerLoaded]);

  const navigate = useNavigate();

  const columns: Column[] = [
    { id: "orgianisation", label: "ORGANISATION", minWidth: 200 },
    { id: "jobSite", label: "JOB SITE", minWidth: 200 },
    {
      id: "camera",
      label: "CAMERA",
      minWidth: 100,
    },
    {
      id: "serialNumber",
      label: "SERIAL NUMBER",
      minWidth: 100,
    },

    { id: "photoDate", label: "PHOTO DATE", minWidth: 100 },
    { id: "photoTime", label: "PHOTO TIME", minWidth: 100 },
    { id: "timeZone", label: "TIME ZONE", minWidth: 100 },
    {
      id: "photoTakenCount",
      label: "TAKEN",
      minWidth: 100,
      description: "Estimated photo taken count of the day",
    },
    {
      id: "photoUploadedCount",
      label: "UPLOADED",
      minWidth: 100,
      description: "Estimated photo uploaded count of the day",
    },
    { id: "battery", label: "BATTERY", minWidth: 100 },
    { id: "solar", label: "SOLAR", minWidth: 100 },
    { id: "action", label: "", minWidth: 100, align: "center" },
  ];

  const rows = useMemo(() => {
    return displayDeviceList.map((device) => {
      const [clientName, jobSiteName] = (device.frontendName || "").split(
        " / ",
      );

      const photoCountInfo = photoCountInfoLookup[device.id as number];
      const deviceDetails = deviceDetailsLookup[device.id as number];
      const lastImage = lastImageLookup[device.id as number];

      const momentWithTimeZone = momentWithTimeZoneHOF(deviceDetails?.timeZone);

      return {
        id: device.id as number,
        orgianisation: clientName || "-",
        jobSite: jobSiteName || "-",
        camera: device.friendlyName || device.deviceId,
        serialNumber: device.deviceId,
        photoDate: lastImage
          ? momentWithTimeZone(lastImage.timestamp).format("DD/MM/YYYY")
          : "-",
        photoTime: lastImage
          ? momentWithTimeZone(lastImage.timestamp).format("HH:mm")
          : "-",
        timeZone: momentWithTimeZone().format("zZ"),
        photoTakenCount: `${photoCountInfo?.taken || 0} / ${
          photoCountInfo?.total || 0
        }`,
        photoUploadedCount: `${photoCountInfo?.uploaded || 0} / ${
          photoCountInfo?.total || 0
        }`,
        battery: !isNaN(Number(device.battery))
          ? `${Number(device.battery).toFixed(2)}v`
          : "-",
        solar: !isNaN(Number(device.solar))
          ? `${Number(device.solar).toFixed(2)}v`
          : "-",
        action: (
          <Box>
            <IconButton
              size="small"
              onClick={(e) => e.stopPropagation()}
              sx={{ mr: 0.5 }}
            >
              <Icon fontSize="small">monitor_heart</Icon>
            </IconButton>

            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                navigate(`./${device.id}/edit`);
              }}
            >
              <Icon fontSize="small">settings</Icon>
            </IconButton>
          </Box>
        ),
      };
    });
  }, [displayDeviceList]);

  return (
    <Box>
      <DashboardListTable
        rows={rows}
        columns={columns}
        isLoading={isLoading}
        noDataText="No device found."
        rowOnClick={(row) => {
          navigate(`/devices/${row.id}`);
        }}
        rowProps={(row, rowIndex) => {
          const device = displayDeviceList[rowIndex];

          const isWarning = isDeviceWarning(device);
          const isError = isDeviceError(device);

          return {
            sx: ({ palette }) => ({
              cursor: "pointer",

              backgroundColor: isError
                ? `${alpha(palette.error.main, 0.5)} !important`
                : isWarning
                ? `${alpha(palette.warning.main, 0.5)} !important`
                : palette.surface.main,

              "&:nth-of-type(even)": {
                backgroundColor: isError
                  ? `${alpha(palette.error.main, 0.6)} !important`
                  : isWarning
                  ? `${alpha(palette.warning.main, 0.6)} !important`
                  : alpha(palette.tertiary.main, 0.4),
              },

              ":hover": {
                backgroundColor: isError
                  ? `${palette.error.main} !important`
                  : isWarning
                  ? `${palette.warning.main} !important`
                  : palette.tertiary.main,
              },
            }),
          };
        }}
      />
    </Box>
  );
};

const DeviceGridWindow = () => {
  const [displayDeviceList, setDisplayDeviceList] = useState<DeviceV2[]>([]);
  const [deviceList, setDeviceList] = useState<DeviceV2[]>([]);
  const [intervalsLookup, setIntervalsLookup] = useState<{
    [key: number]: IntervalV2;
  }>({});
  const [photoCountInfoLookup, setPhotoCountInfoLookup] = useState<{
    [key: number]: PhotoCountInfo;
  }>({});
  const [deviceDetailsLookup, setDeviceDetailsLookup] = useState<{
    [key: number]: DeviceV2Details;
  }>({});
  const [lastImageLookup, setLastImageLookup] = useState<{
    [key: number]: FirebaseImage;
  }>({});

  const [currentSortType, setCurrentSortType] = useLocalStorage<SortType>(
    "devices-sort-type",
    {
      type: SORT_OPTIONS[0],
      isAsc: true,
    },
  );

  const [currentViewType, setcurrentViewType] = useLocalStorage(
    "devices-view-type",
    "grid",
  );

  const { trackAction } = useDashboardTracker("devices", true);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const search = useAtomValue(searchState);

  const navigate = useNavigate();

  useEffect(() => {
    initStates();
  }, []);

  useEffect(() => {
    handleSearch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.onSearch, currentViewType]);

  const initStates = async () => {
    setIsLoading(true);

    const firebaseController = getFirebaseController();

    await firebaseController.Device.getDevices()
      .then(async (data) => {
        const deviceList = data;

        const sortedList = sortDevices(
          deviceList,
          currentSortType.type,
          currentSortType.isAsc ? "asc" : "desc",
        );

        const intervalPromises: Promise<any>[] = [];
        const uploadedEventsPromises: Promise<any>[] = [];
        const deviceDetailsPromises: Promise<any>[] = [];
        const lastImagePromises: Promise<any>[] = [];

        const intervalLookup = {};
        const photoCountInfoLookup: any = {};
        const deviceDetailsLookup: any = {};
        const lastImageLookup: any = {};

        deviceList.forEach((device) => {
          photoCountInfoLookup[device.id as number] = {
            total: 0,
            taken: 0,
            uploaded: 0,
          } as PhotoCountInfo;

          intervalPromises.push(
            firebaseController.Device.getDeviceInterval(
              device.id as number,
              moment().format("dddd"),
            ).then(async (interval?: IntervalV2) => {
              intervalLookup[device.id as number] = interval;

              if (interval) {
                const [takenCount, totalCount] = getPhotoTakenCounts(
                  interval.timeFrom,
                  interval.timeTo,
                  interval.timeBetweenShots,
                );

                photoCountInfoLookup[device.id as number].total = totalCount;
                photoCountInfoLookup[device.id as number].taken = takenCount;
              }

              return intervalLookup;
            }),
          );

          uploadedEventsPromises.push(
            firebaseController.Device.getDeviceEvents(
              device.id || "",
              moment().startOf("days").unix(),
              moment().endOf("days").unix(),
              1,
            ).then((data) => {
              photoCountInfoLookup[device.id as number].uploaded = (
                data || []
              ).length;

              return data;
            }),
          );

          deviceDetailsPromises.push(
            firebaseController.Device.getDeviceDetails(
              device.id as number,
            ).then((data) => {
              deviceDetailsLookup[device.id as number] = data;
            }),
          );

          lastImagePromises.push(
            firebaseController.Image.getLastImage(device.id as number).then(
              (data) => {
                lastImageLookup[device.id as number] = data;
              },
            ),
          );
        });

        Promise.all([
          ...intervalPromises,
          ...uploadedEventsPromises,
          ...deviceDetailsPromises,
          ...lastImagePromises,
        ]).then(() => {
          setPhotoCountInfoLookup(photoCountInfoLookup);
          setIntervalsLookup(intervalLookup);
          setDeviceDetailsLookup(deviceDetailsLookup);
          setLastImageLookup(lastImageLookup);

          setDeviceList(sortedList);
          setDisplayDeviceList(sortedList);
        });
      })
      .catch((err) => {
        console.error(err);
      });

    setIsLoading(false);
  };

  const sortDevices = (
    devices: DeviceV2[],
    sortType: string,
    order,
  ): DeviceV2[] => {
    let newDeviceList = _.cloneDeep(devices);

    switch (sortType) {
      case "Device Name":
        trackAction("sort device name");

        newDeviceList = orderByIgnoreCase(newDeviceList, "deviceId", order);
        break;

      case "Friendly Name":
        trackAction("sort friendly name");

        newDeviceList = _.orderBy(
          newDeviceList,
          [(device) => device.friendlyName === "", "friendlyName", "deviceId"],
          [order, order, order],
        );

        break;

      case "Gallery Name":
        trackAction("sort gallery name");

        newDeviceList = _.orderBy(
          newDeviceList,
          [
            (device) => device.frontendName?.toLowerCase(),
            (device) => device.friendlyName?.toLowerCase(),
          ],
          [order, order, order],
        );

        break;

      case "Interval":
        trackAction("sort interval");

        newDeviceList = _.orderBy(
          newDeviceList,
          [
            (device) => {
              const interval = intervalsLookup[device.id as number];

              return interval?.timeBetweenShots || 0;
            },
            "deviceId",
          ],
          order,
        );

        break;

      case "Battery":
        trackAction("sort battery");

        newDeviceList = _.orderBy(
          newDeviceList,
          (device) => {
            const power = device.battery;

            return power === undefined ? -1 : power;
          },
          order,
        );
        break;

      case "Solar":
        trackAction("sort solar");

        newDeviceList = _.orderBy(
          newDeviceList,
          (device) => {
            const solar = device.solar;

            return solar === undefined ? -1 : solar;
          },
          order,
        );
        break;

      case "Capture":
        trackAction("sort capture");

        newDeviceList = _.orderBy(
          newDeviceList,
          (device) => {
            const captureCount =
              photoCountInfoLookup[device.id as number]?.taken || 0;

            return captureCount;
          },
          order,
        );

        break;

      case "Alert":
        trackAction("sort alert");

        newDeviceList = _.orderBy(
          newDeviceList,
          [
            (device) => {
              const level = isDeviceError(device)
                ? 0
                : isDeviceWarning(device)
                ? 1
                : 2;

              return level;
            },
            "deviceId",
          ],
          [order, order],
        );

        break;

      default:
        break;
    }

    return newDeviceList;
  };

  const handleSort = (sortType: string) => {
    const isAsc =
      currentSortType.type === sortType ? !currentSortType.isAsc : true;
    const order = isAsc ? "asc" : "desc";

    const newDeviceList = sortDevices(displayDeviceList, sortType, order);

    setCurrentSortType({
      type: sortType,
      isAsc,
    });

    setDisplayDeviceList(newDeviceList);
  };

  const handleSearch = () => {
    let newDeviceList = _.cloneDeep(deviceList);

    if (search.value) {
      trackAction("search");

      newDeviceList = newDeviceList.filter((device) => {
        const searchFieldValues = [
          device.friendlyName,
          device.deviceId,
          device.id,
        ];

        return searchFieldValues.some((value) => {
          return (
            value &&
            _.toLower(value.toString()).includes(_.toLower(search.value))
          );
        });
      });
    }

    setDisplayDeviceList(newDeviceList);
  };

  const isDeviceWarning = (device: DeviceV2) => {
    return (
      device.solar < WARNING_SOLAR_VOLTAGE_MIN &&
      device.battery < WARNING_BATTERY_VOLTAGE_MIN
    );
  };

  const isDeviceError = (device: DeviceV2) => {
    return !device.solar && !device.battery;
  };

  const headerRef = useRef<HTMLDivElement>(null);

  return (
    <DashboardContainer NavbarProps={{ title: "Device Dashboard" }}>
      <DashboardHeader
        sortOptions={SORT_OPTIONS}
        currentSortType={currentSortType}
        handleSort={handleSort}
        viewOptions={[
          {
            label: "Grid",
            onClick: () => setcurrentViewType("grid"),
            key: "grid",
          },
          {
            label: "List",
            onClick: () => setcurrentViewType("list"),
            key: "list",
          },
        ]}
        currentViewType={currentViewType}
      />

      {
        {
          grid: (
            <GridView
              displayDeviceList={displayDeviceList}
              intervalsLookup={intervalsLookup}
              photoCountInfoLookup={photoCountInfoLookup}
              deviceDetailsLookup={deviceDetailsLookup}
              lastImageLookup={lastImageLookup}
              isDeviceWarning={isDeviceWarning}
              isDeviceError={isDeviceError}
            />
          ),
          list: (
            <ListView
              displayDeviceList={displayDeviceList}
              intervalsLookup={intervalsLookup}
              photoCountInfoLookup={photoCountInfoLookup}
              deviceDetailsLookup={deviceDetailsLookup}
              lastImageLookup={lastImageLookup}
              headerRef={headerRef}
              isDeviceWarning={isDeviceWarning}
              isDeviceError={isDeviceError}
              isLoading={isLoading}
            />
          ),
        }[currentViewType]
      }
    </DashboardContainer>
  );
};

export default DeviceGridWindow;
