import { Box, useTheme, Typography } from "@mui/material";
import _ from "lodash";
import { memo, useCallback } from "react";
import {
  LineChart as ReLineChart,
  Line,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Label,
} from "recharts";
import {
  AxisDomain,
  AxisInterval,
  BaseAxisProps,
} from "recharts/types/util/types";

export type LineChartOptions = {
  colors?: string[];
  xLabel?: string;
  yLabel?: string;
  xKey?: string;
  yKey?: string;
  xDomain?: AxisDomain;
  yDomain?: AxisDomain;
  xInterval?: AxisInterval;
  yInterval?: AxisInterval;
  xTickFormatter?: BaseAxisProps["tickFormatter"];
  yTickFormatter?: BaseAxisProps["tickFormatter"];
  unit?: string;
  showLegend?: boolean;
  minHeight?: number;
};

const LineChart = memo(
  ({
    id,
    title,
    data,
    options,
    onItemClick,
  }: {
    id?: any;
    data: any[];
    title?: string;
    options?: LineChartOptions;
    onItemClick?: (item, itemIndex) => void;
  }) => {
    const { palette } = useTheme();

    const { name, ...keys } = data[0] || {};

    const { yKey, xKey, showLegend = true, minHeight = 300 } = options || {};

    const dataKeys = Object.keys(keys);

    const colors = options?.colors || [
      palette.primary.main,
      palette.warning.main,
      "black",
    ];

    const CustomLabel = ({
      x,
      y,
      stroke,
      value,
      width,
    }: {
      x?: any;
      y?: any;
      stroke?: any;
      value?: any;
      width?: any;
    }) => {
      if (value) {
        // No label if there is a value. Let the cell handle it.
        return null;
      }

      return (
        <text
          x={x}
          y={y}
          // Move slightly above axis
          dy={-10}
          // Center text
          dx={width / 2}
          fill={stroke}
          fontSize={15}
          textAnchor="middle"
        >
          N/A
        </text>
      );
    };

    const handleItemClick = (itemIndex: number) => {
      if (onItemClick) {
        onItemClick(data[itemIndex], itemIndex);
      }
    };

    const bindClick = useCallback(() => {
      if (onItemClick) {
        return {
          onClick: (payload) => {
            if (!_.isUndefined(payload.activeTooltipIndex)) {
              handleItemClick(payload.activeTooltipIndex);
            }
          },
          onMouseMove: (payload, event) => {
            if (!_.isUndefined(payload.activeTooltipIndex)) {
              event.target.style.cursor = "pointer";
            } else {
              event.target.style.cursor = "auto";
            }
          },
          onMouseLeave: (payload, event) => {
            event.target.style.cursor = "auto";
          },
        };
      } else {
        return {};
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onItemClick]);

    return (
      <Box>
        {title && (
          <Typography
            sx={{
              mb: 2,
              fontSize: 16,
              fontWeight: "bold",
              textDecoration: "underline",
              color: ({ palette }) => palette.grey[700],
            }}
          >
            {title}
          </Typography>
        )}

        {data.length > 0 ? (
          <ResponsiveContainer
            width="100%"
            height="100%"
            minHeight={minHeight}
            debounce={300}
          >
            <ReLineChart
              key={id}
              data={data}
              margin={{
                top: 10,
                right: 10,
                left: 0,
                bottom: 10,
              }}
              {...bindClick()}
            >
              <CartesianGrid strokeDasharray="1 1" stroke={palette.grey[300]} />

              {showLegend && (
                <Legend
                  align="right"
                  fontSize={8}
                  iconType="circle"
                  iconSize={12}
                  wrapperStyle={{
                    width: "max-content",
                    border: `1px solid ${palette.grey[400]}`,
                    padding: "0 20px",
                    fontSize: 14,
                  }}
                />
              )}

              <XAxis
                style={{
                  marginBottom: "200px",
                }}
                xlinkTitle={"a"}
                dataKey={xKey || "name"}
                fontSize={12}
                stroke={palette.grey[700]}
                domain={options?.xDomain || [0, "auto"]}
                interval={options?.xInterval}
                tickFormatter={options?.xTickFormatter}
              >
                {options?.xLabel && (
                  <Label
                    position="center"
                    dy={20}
                    fontSize={14}
                    fontWeight={"bold"}
                    value={options.xLabel}
                  />
                )}
              </XAxis>

              <YAxis
                allowDataOverflow={true}
                fontSize={12}
                stroke={palette.grey[700]}
                domain={options?.yDomain || [0, "auto"]}
                interval={options?.yInterval}
              >
                {options?.yLabel && (
                  <Label
                    position="center"
                    dx={-20}
                    angle={-90}
                    fontSize={14}
                    fontWeight={"bold"}
                    value={options.yLabel}
                  />
                )}
              </YAxis>

              <Tooltip
                formatter={(value) => {
                  return options?.unit ? `${value} ${options.unit}` : value;
                }}
              />

              {(yKey ? [yKey] : dataKeys).map((key, index) => {
                return (
                  <Line
                    key={key}
                    animationDuration={1000}
                    type="linear"
                    dataKey={key}
                    stroke={colors[index]}
                    strokeWidth={3}
                  />
                );
              })}
            </ReLineChart>
          </ResponsiveContainer>
        ) : (
          <Box
            sx={{
              minHeight,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: 12,
                color: ({ palette }) => palette.grey[600],
              }}
            >
              No data.
            </Typography>
          </Box>
        )}
      </Box>
    );
  },
  (prevProps, nextProps) => {
    return _.isEqual(prevProps, nextProps);
  },
);

export default LineChart;
