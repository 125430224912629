import { ReactElement, memo } from "react";

import {
  Box,
  Paper,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Button,
  ButtonProps,
  TextField,
  TextFieldProps,
  BoxProps,
  TableContainerProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogContentTextProps,
  DialogTitle,
  DialogTitleProps,
  Link as MuiLink,
  IconButton,
  lighten,
  alpha,
} from "@mui/material";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

import _ from "lodash";
import { Link, LinkProps } from "react-router-dom";
import { OpenInNew } from "@mui/icons-material";
import { getContrastShade } from "theme/reliveItTheme";

export interface SettingRow {
  id?: string | number;
  settingName: string | ReactElement;
  value: ReactElement;
}

export interface JobSiteRows {
  id?: string | number;
  name: ReactElement;
  email: ReactElement;
  physicalAddress: ReactElement;
  actions: ReactElement;
}

export interface UserRow {
  id?: string | number;
  name: ReactElement;
  jobSites: ReactElement;
  actions: ReactElement;
}

export interface UserRoleRow {
  id?: string | number;
  client: ReactElement;
  accessLevel: ReactElement;
  accessableJobSites: ReactElement;
  actions: ReactElement;
}

export interface IntervalSettingsRow {
  id?: string | number;
  active?: ReactElement;
  day: ReactElement;
  startTime: ReactElement;
  finishTime: ReactElement;
  interval: ReactElement;
  action: ReactElement;
}

export interface Column {
  id: string;
  label: string;
  minWidth?: number | string;
  maxWidth?: number | string;
  width?: number | string;
  align?: "left" | "center" | "right";
  verticalAlign?: "top" | "center" | "bottom";
  format?: (value: number) => string;
  description?: string;
}

export const columns: Column[] = [
  { id: "settingName", label: "Setting Name", width: "30%" },
  { id: "value", label: "Value", width: "70%" },
];

type SettingsTableProps = {
  title: string;
  rows:
    | SettingRow[]
    | IntervalSettingsRow[]
    | JobSiteRows[]
    | UserRoleRow[]
    | UserRow[];
  columns: Column[];
  headerActions?: ReactElement;
  HeaderActionsProps?: BoxProps;
  TableContainerProps?: TableContainerProps;
  noItemLabel?: string;
  isLoading?: boolean;
};

export type SettingConfirmationModalProps = {
  isOpen: boolean;
  title?: DialogTitleProps["children"];
  content?: DialogContentTextProps["children"];
  onClose: () => void;
  onDone: () => void;
};

export type SettingConfirmationModalParams = Omit<
  SettingConfirmationModalProps,
  "onClose"
> &
  Partial<SettingConfirmationModalProps>;

export const SettingsTableHeaderButton = (props: ButtonProps) => {
  return (
    <Button
      sx={{
        ml: 1.5,
        color: "white !important",
        fontSize: {
          xs: 10,
          sm: 12,
        },
        minWidth: "auto",
        whiteSpace: "nowrap",
        // width: "100%",
      }}
      size="small"
      color="primary"
      variant="contained"
      disableElevation
      {...props}
    >
      {props.children}
    </Button>
  );
};

export const SettingsTableHeaderTextField = (props: TextFieldProps) => {
  return (
    <TextField
      InputProps={{
        sx: {
          fontSize: "0.875rem",
        },
      }}
      inputProps={{
        sx: {
          padding: "6px 8px",
          background: "white",
          fontSize: {
            xs: 10,
            sm: 13,
          },
        },
      }}
      sx={{
        width: "100%",
      }}
      size="small"
      {...props}
    />
  );
};

export const SettingConfirmationModal = ({
  isOpen,
  title,
  content,
  onClose,
  onDone,
}: SettingConfirmationModalProps) => {
  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        sx: {
          margin: {
            xs: 2,
          },
        },
      }}
      onClose={onClose}
    >
      {title && <DialogTitle>{title}</DialogTitle>}

      {content && (
        <DialogContent>
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>
      )}

      <DialogActions>
        <Button size="small" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          size="small"
          variant="contained"
          color="error"
          disableElevation
          onClick={() => {
            onDone();
            onClose();
          }}
          autoFocus
          sx={{
            color: "white !important",
          }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const SettingLink = (
  props: LinkProps & { icon?: ReactElement; noMargin?: boolean },
) => {
  return (
    <MuiLink
      component={Link}
      sx={{
        fontSize: 12,
        ml: props.noMargin ? 0 : 0.5,
        display: "flex",
        alignItems: "center",

        ":hover": {
          color: ({ palette }) => palette.primary.light,
          textDecoration: "underline !important",
        },
      }}
      {...props}
    >
      {props.children ? (
        props.children
      ) : (
        <IconButton
          size="small"
          color="primary"
          sx={{
            ".MuiSvgIcon-root": {
              fontSize: 14,
              color: ({ palette }) => palette.primary.main,

              transition: ({ transitions }) => transitions.create("color"),
            },

            ":hover": {
              ".MuiSvgIcon-root": {
                fontSize: 14,
                color: ({ palette }) =>
                  getContrastShade(palette.primary, "dark"),
              },
            },
          }}
        >
          {props.icon ? props.icon : <OpenInNew />}
        </IconButton>
      )}
    </MuiLink>
  );
};

const SettingsTableText = ({
  children,
  columnCount,
}: {
  children: string;
  columnCount: number;
}) => {
  return (
    <TableRow sx={{ position: "relative" }}>
      <TableCell colSpan={columnCount}>
        <Box
          sx={{
            position: "sticky",
            top: 0,
            left: "50%",
            transform: "translateX(-50%)",
            width: "max-content",
          }}
        >
          <Typography
            variant="caption"
            sx={{ color: ({ palette }) => alpha(palette.onSurface.main, 0.9) }}
          >
            {children}
          </Typography>
        </Box>
      </TableCell>
    </TableRow>
  );
};

const SettingsTable = ({
  title,
  rows,
  columns,
  headerActions,
  HeaderActionsProps,
  TableContainerProps,
  noItemLabel,
  isLoading,
}: SettingsTableProps) => {
  return (
    <Box
      component={Paper}
      sx={{
        border: ({ palette }) =>
          `1px solid ${alpha(palette.onSurface.main, 0.2)}`,
        boxShadow: 0,
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          borderBottom: ({ palette }) =>
            `1px solid ${alpha(palette.onSurface.main, 0.2)}`,
          backgroundColor: ({ palette }) => palette.tertiary.main,
          background: ({ palette }) =>
            `linear-gradient(0deg, ${alpha(
              getContrastShade(palette.tertiary, "light"),
              0.4,
            )} 0%,${alpha(
              getContrastShade(palette.tertiary, "light"),
              0.3,
            )} 100%)`,
          color: ({ palette }) => `${palette.onSurface.main} !important`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            p: 1.5,
            flexWrap: "wrap",
            justifyContent: "space-between",
            rowGap: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <FormatListBulletedIcon
              sx={{
                mr: 1,
                fontSize: {
                  xs: 13,
                  sm: 20,
                },
              }}
              fontSize="small"
            />

            <Typography
              sx={{
                fontWeight: "bold",
                lineHeight: 1,
                fontSize: {
                  xs: 13,
                  sm: 16,
                },
              }}
            >
              {title}
            </Typography>
          </Box>

          {headerActions && (
            <Box
              {...HeaderActionsProps}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                ...HeaderActionsProps?.sx,
              }}
            >
              {headerActions}
            </Box>
          )}
        </Box>
      </Box>

      <TableContainer {...TableContainerProps}>
        <Table
          sx={{
            td: {
              p: {
                xs: 1.25,
                sm: 1.5,
              },
              fontSize: {
                xs: 12,
                sm: 14,
              },
              "& p": {
                fontSize: {
                  xs: 12,
                  sm: 14,
                },
              },
            },
            th: {
              fontSize: {
                xs: 12,
                sm: 14,
              },
              lineHeight: {
                xs: 1,
                sm: 1.5,
              },
            },
          }}
        >
          <TableHead
            sx={{
              ".MuiTableCell-root": {
                color: ({ palette }) => `${palette.onSurface.main} !important`,
              },
            }}
          >
            <TableRow
              sx={{
                borderBottom: ({ palette }) =>
                  `1px solid ${alpha(palette.onSurface.main, 0.2)} !important`,
              }}
            >
              {columns.map((column, columnIndex) => (
                <Tooltip title={column.description} key={column.id}>
                  <TableCell
                    key={column.id}
                    align={column.align}
                    sx={{
                      minWidth: column.minWidth,
                      width: column.width || "min-content",
                      textWrap: "nowrap",
                      p: 1.5,

                      background: ({ palette }) =>
                        `linear-gradient(0deg, ${alpha(
                          getContrastShade(palette.tertiary, "light"),
                          0.4,
                        )} 0%,${alpha(
                          getContrastShade(palette.tertiary, "light"),
                          0.2,
                        )} 100%)`,

                      borderBottom: 0,

                      borderRight: ({ palette }) =>
                        `1px solid ${alpha(
                          palette.onSurface.main,
                          0.2,
                        )} !important`,

                      "&:last-child": {
                        borderRight: "0 !important",
                      },
                    }}
                  >
                    {column.label}

                    {column.description && (
                      <Typography
                        variant="caption"
                        sx={{
                          fontSize: 12,
                          verticalAlign: "super",
                        }}
                      >
                        *
                      </Typography>
                    )}
                  </TableCell>
                </Tooltip>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {isLoading ? (
              <SettingsTableText columnCount={columns.length}>
                Loading...
              </SettingsTableText>
            ) : rows.length > 0 ? (
              <>
                {rows.map((row, rowIndex) => {
                  return (
                    <TableRow
                      key={row.id || rowIndex}
                      sx={({ palette }) => ({
                        backgroundColor: palette.surface.main,

                        "&:nth-of-type(even)": {
                          // backgroundColor: palette.action.hover,
                          backgroundColor: alpha(
                            getContrastShade(palette.onSurface, "dark"),
                            0.1,
                          ),
                        },

                        borderBottom: `1px solid ${alpha(
                          palette.onSurface.main,
                          0.2,
                        )}`,

                        "&:last-child": {
                          borderBottom: "0 !important",
                        },
                      })}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            sx={{
                              borderRight: ({ palette }) =>
                                `1px solid ${alpha(
                                  palette.onSurface.main,
                                  0.2,
                                )} !important`,

                              p: 1.5,
                              borderBottom: "0 !important",

                              "&:last-child": {
                                borderRight: "0 !important",
                              },

                              verticalAlign: column.verticalAlign || "center",
                            }}
                          >
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </>
            ) : (
              <SettingsTableText columnCount={columns.length}>
                {noItemLabel || "Data not found."}
              </SettingsTableText>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default memo(SettingsTable, (prevProps, nextprops) => {
  return _.isEqual(prevProps, nextprops);
});
