import {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useParams, useLocation, useNavigate, Link } from "react-router-dom";

import { faDroplet } from "@fortawesome/free-solid-svg-icons";

import {
  Autocomplete,
  Avatar,
  Badge,
  Box,
  Button,
  Chip,
  ClickAwayListener,
  Divider,
  Drawer,
  Fade,
  Grid,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Slider,
  Stack,
  TextField,
  Theme,
  Tooltip,
  Typography,
  alpha,
  createFilterOptions,
  keyframes,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  DateCalendar,
  LocalizationProvider,
  PickersDay,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import {
  Close,
  KeyboardArrowUp,
  LocationDisabled,
  OpenInNew,
} from "@mui/icons-material";

import ReliveItLogo from "assets/BEERAssets/ReliveIt/Relive-IT-Logo-White.png";

import MenuIcon from "@mui/icons-material/Menu";
import StarIcon from "@mui/icons-material/Star";
import ChatIcon from "@mui/icons-material/Chat";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import InfoIcon from "@mui/icons-material/Info";
import DownloadIcon from "@mui/icons-material/Download";
import VideocamIcon from "@mui/icons-material/Videocam";
import AppsIcon from "@mui/icons-material/Apps";
import SettingsIcon from "@mui/icons-material/Settings";
import SliderIcon from "components/Icons/SliderIcon";
import CompareIcon from "components/Icons/CompareIcon";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import LDSRingLoader from "components/Loaders/LDSRingLoader";
import HighResImageV2, { MAX_ZOOM, MIN_ZOOM } from "components/HighResImageV2";
import WeatherIcon from "components/Icons/WeatherIcon";
import ConfirmationModal from "components/BaseModal/ConfirmationModal";

import { getFirebaseController } from "database/FirebaseController";
import ImageHandlerV2, {
  Image,
  Thumbnail,
  WeatherLocation,
} from "database/ImageHandlerV2";
import {
  Comment,
  DeviceV2,
  DropBoxImage,
  FirebaseImage,
  GalleryV2,
  JobSite,
  UnixEpoch,
} from "database/DataTypes";
import { DEFAULT_IMAGE_TAGS } from "database/DataDefaultValues";

import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { currentUserRoleState, currentUserState } from "states/auth";
import {
  currentGalleryJobsiteState,
  selectedImageState,
  imageViewerComponentCahcesState,
  gridComponentCahcesState,
  currentGalleryClientState,
  currentWeatherImageState,
  weatherReportComponentCahcesState,
} from "states/imageViewer";
import {
  clientListCacheState,
  deviceListCacheState,
  galleryJobSiteLookupCacheState,
  galleryListCacheState,
} from "states/caches";
import { isSidenavShowState, themeSettingsState } from "states/layout";

import moment from "moment";
import _ from "lodash";

import * as piexif from "piexifjs";
import { useDrag } from "@use-gesture/react";

import useAccess from "hooks/useAccess";
import useWeather, {
  FaWeatherIconName,
  getFaWeatherIcon,
} from "hooks/useWeather";
import useImageViewerTracker, {
  TrackImageViewerAction,
} from "hooks/eventTracker/useImageViewerTracker";
import { TimelapsePlayerTrackAction } from "hooks/eventTracker/useTimelapsePlayerTracker";
import FontAwesomeSvgIcon from "components/Icons/FontAwesomeSvgIcon";
import { momentWithTimeZoneHOF } from "hooks/useTimeZone";
import { orderByIgnoreCase } from "utils/display";
import { getContrastShade } from "theme/reliveItTheme";

export const backgroundFlash = keyframes`
  from {
    background-color: #7ae4b8;
  }

  to {
    background-color: none;
  }
`;

const getFlashStyle = (isFlash: boolean = true) => {
  return isFlash
    ? {
        animationName: `${backgroundFlash}`,
        animationDuration: "1s",
        animationIterationCount: "initial",
      }
    : {};
};

interface SelectOptionType {
  value: string;
  label: string;
  inputValue?: string;
}

const createSelectOption = (
  value: string,
  label?: string,
): SelectOptionType => {
  return { value, label: label || value };
};

const DrawerContainer = ({ open, onClose, children }) => {
  return (
    <Drawer
      anchor={"right"}
      open={open}
      variant={"persistent"}
      PaperProps={{
        sx: ({ zIndex, customConfig, palette }) => ({
          boxShadow: 3,
          zIndex: zIndex.appBar - 1,
          borderRadius: 0,
          // marginTop: customConfig.imageViewerNavbarHeight,
          height: "100%",
          pt: customConfig.imageViewerNavbarHeight,
          pb: {
            xs: `calc(${customConfig.imageViewerNavbarHeight}* 2)`,
            md: customConfig.imageViewerNavbarHeight,
          },
          width: { xs: "100%", sm: "50%", md: "25%", lg: "20%" },
          background: "transparent",
          overflow: "hidden",
        }),
      }}
      onClose={onClose}
    >
      <Box
        sx={{
          height: "100%",
          position: "relative",

          opacity: {
            xs: 1,
            sm: 0.8,
          },
          transition: ({ transitions }) =>
            `${transitions.create("all")} !important`,

          ":hover": {
            opacity: 1,
          },
        }}
      >
        <Box
          sx={{
            height: "100%",
            backgroundColor: ({ palette }) => palette.surface.main,

            overflow: "auto",
          }}
        >
          {children}
        </Box>

        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            background: `linear-gradient(0deg, rgba(255,255,255,1) 10%, rgba(255,255,255,0) 100%)`,
            height: 100,
          }}
        />
      </Box>
    </Drawer>
  );
};

const InfoContainer = ({ open, children }) => {
  // const bottomRef = useRef(null);

  // const isScrollBottom = useOnScreen(bottomRef);

  return (
    <Fade in={open} mountOnEnter unmountOnExit>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          width: { xs: "100%", sm: "50%", md: "25%", lg: "20%" },
          minWidth: 200,
          zIndex: 10,
          maxHeight: ({ customConfig }) =>
            `calc(100vh - ${customConfig.imageViewerNavbarHeight} - ${customConfig.imageViewerNavbarHeight} )`,
          height: "100%",
          overflow: "auto",
        }}
      >
        <Box
          sx={{
            backgroundColor: ({ palette }) => palette.surface.main,
            opacity: {
              xs: 1,
              sm: 0.8,
            },
            transition: ({ transitions }) =>
              `${transitions.create("all")} !important`,

            ":hover": {
              opacity: 1,
            },
          }}
        >
          <Box>{children}</Box>
        </Box>
      </Box>
    </Fade>
  );
};

const CommentsSection = ({
  imageHandler,
  currentThumbnail,
  handleClose,
  isCommentsOpened,
  setIsCommentsOpened,
  setIsKeyPressAllowed,
  commentList,
  setCommentList,
}) => {
  const currentUser = useAtomValue(currentUserState);

  const [commentInput, setCommentInput] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isFlash, setIsFlash] = useState<boolean>(false);

  const bottomRef = useRef<any>();

  const getComments = async () => {
    const comments = await imageHandler.getImageComments(currentThumbnail);

    setCommentList(comments);
  };

  const stringToColor = (string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  const getInitials = (name) => {
    const chars = _.words(name);
    const initials = chars.map((char) => _.upperCase(_.first(char)));

    return initials.join("");
  };

  const handleCommentSubmit = async () => {
    setIsSubmitting(true);

    await imageHandler
      .updateImageComment(currentThumbnail, commentInput)
      .then(async () => {
        setCommentInput("");
        setIsSubmitting(false);
        await getComments();

        setIsFlash(true);
        bottomRef?.current?.scrollIntoView();
      })
      .catch((err) => {
        console.error(err);
        setIsSubmitting(false);
      });
  };

  return (
    <DrawerContainer
      open={isCommentsOpened}
      onClose={() => setIsCommentsOpened(!isCommentsOpened)}
    >
      {currentUser && (
        <>
          <Box>
            <IconButton
              onClick={handleClose}
              sx={({ palette }) => ({
                position: "absolute",
                top: 0,
                right: 0,
              })}
            >
              <Close />
            </IconButton>
          </Box>
          <Box
            p={2}
            mt={2}
            pb={({ customConfig }) => customConfig.imageViewerNavbarHeight}
          >
            <Box>
              <Grid container spacing={2} sx={{ alignItems: "flex-start" }}>
                <Grid item>
                  <Avatar
                    variant="square"
                    sx={({ palette }) => ({
                      backgroundColor: stringToColor(currentUser.username),
                      color: palette.getContrastText(
                        stringToColor(currentUser?.username),
                      ),
                      width: 30,
                      height: 30,
                    })}
                  >
                    {getInitials(currentUser?.username)}
                  </Avatar>
                </Grid>

                <Grid item xs zeroMinWidth>
                  <Box>
                    <Typography
                      lineHeight={1}
                      fontSize={16}
                      fontWeight={"bold"}
                    >
                      You
                    </Typography>
                  </Box>

                  <Box mt={1}>
                    <TextField
                      size="small"
                      onFocus={() => setIsKeyPressAllowed(false)}
                      onBlur={() => setIsKeyPressAllowed(true)}
                      disabled={isSubmitting}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      multiline
                      rows={4}
                      variant="outlined"
                      placeholder="Add a comment..."
                      value={commentInput}
                      onChange={(e) => setCommentInput(e.target.value)}
                    />

                    <Button
                      disabled={isSubmitting}
                      onClick={handleCommentSubmit}
                      variant="contained"
                      size="small"
                      sx={{
                        mt: 1,
                        display: "block",
                        marginLeft: "auto",
                        color: ({ palette }) => palette.onPrimary.main,
                      }}
                      disableElevation
                    >
                      Submit
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Divider variant="fullWidth" style={{ margin: "1rem 0" }} />

            <Box>
              <Typography
                sx={{
                  fontSize: {
                    xs: 14,
                    sm: 18,
                  },
                }}
              >
                {`${commentList.length} Comments:`}
              </Typography>

              {commentList.map((comment, commentIndex) => {
                return (
                  <Fragment key={comment.id}>
                    <Paper
                      sx={{
                        p: 2,
                        mt: 1,
                        ...getFlashStyle(
                          isFlash && commentIndex === commentList.length - 1,
                        ),
                      }}
                      onAnimationEnd={() => setIsFlash(false)}
                    >
                      <Grid container spacing={2}>
                        <Grid item>
                          <Avatar
                            variant="square"
                            sx={({ palette }) => ({
                              backgroundColor: stringToColor(
                                comment.user?.username,
                              ),
                              color: palette.getContrastText(
                                stringToColor(comment.user?.username),
                              ),

                              width: 25,
                              height: 25,
                            })}
                          >
                            {getInitials(comment.user?.username)}
                          </Avatar>
                        </Grid>
                        <Grid item xs zeroMinWidth>
                          <Typography
                            lineHeight={1}
                            fontSize={16}
                            fontWeight={"bold"}
                          >
                            {currentUser.id === comment.user?.id
                              ? "You"
                              : comment.user?.username}
                          </Typography>

                          <Typography fontSize={12}>
                            {moment(comment.epochTime).fromNow()}
                          </Typography>

                          <Typography
                            fontSize={14}
                            textAlign={"justify"}
                            sx={{ textWrap: "wrap" }}
                          >
                            {comment.comment}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Paper>

                    <Divider variant="fullWidth" style={{ margin: "1rem 0" }} />
                  </Fragment>
                );
              })}
            </Box>

            <Box ref={bottomRef} />
          </Box>
        </>
      )}
    </DrawerContainer>
  );
};

const TagsSection = ({
  tagList,
  setTagList,
  setIsKeyPressAllowed,
  thumbnail,
  imageHandler,
  isTagsOpened,
  setIsTagsOpened,
}) => {
  const [tagRemoveModalParams, setTagRemoveModalParams] = useState<{
    isOpen: boolean;
    tag: string;
    clearAll: boolean;
    actionObject: any;
  }>({
    isOpen: false,
    tag: "",
    clearAll: false,
    actionObject: {},
  });

  const handleTagChange = async (actionObject) => {
    if (thumbnail) {
      const { reason, option } = actionObject;

      const isAdd = reason === "createOption" || reason === "selectOption";

      let tags;

      if (!option) {
        tags = [...tagList];
      } else if (typeof option === "string") {
        tags = [option];
      } else {
        tags = [
          (option as SelectOptionType).inputValue ||
            (option as SelectOptionType).value,
        ];
      }

      await imageHandler!
        .updateImageTag(thumbnail, tags, isAdd)
        .then((imageExtras) => {
          setTagList(imageExtras.tags || []);
        });
    }
  };

  const getTagsOptions = () => {
    const existingTags = imageHandler?.getAllImageTags() || [];

    const availableTags = [...existingTags, ...DEFAULT_IMAGE_TAGS].filter(
      (tag) => {
        return !tagList.includes(tag);
      },
    );

    return _.sortBy(_.uniq(availableTags)).map((tag) =>
      createSelectOption(tag),
    );
  };

  const confirmModalTitle = tagRemoveModalParams.clearAll
    ? "Remove All Tags?"
    : `Remove Tag "${tagRemoveModalParams.tag}"?`;
  const confirmModalMessage = tagRemoveModalParams.clearAll
    ? `Are you sure you want to remove all tags from this image? This process cannot be undone.`
    : `Are you sure you want to remove tag "${tagRemoveModalParams.tag}" from this image? This process cannot be undone.`;

  return (
    <Fade in={isTagsOpened} mountOnEnter unmountOnExit>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          zIndex: 10,
          display: "flex",
        }}
      >
        <Autocomplete
          onFocus={() => setIsKeyPressAllowed(false)}
          onBlur={() => setIsKeyPressAllowed(true)}
          sx={{
            backgroundColor: ({ palette }) => alpha(palette.surface.main, 0.2),

            ".MuiInputBase-root": {
              color: ({ palette }) => palette.onSecondary.main,
            },

            ".Mui-focused": {
              backgroundColor: ({ palette }) =>
                alpha(palette.surface.main, 0.4),
              color: ({ palette }) => palette.onSurface.main,

              ".MuiAutocomplete-endAdornment .MuiButtonBase-root": {
                color: ({ palette }) => alpha(palette.onSurface.main, 0.8),
                borderRadius: 1,
                p: 1,

                ":hover": {
                  color: ({ palette }) => alpha(palette.onSurface.main, 1),
                },
              },
            },

            ".MuiAutocomplete-endAdornment .MuiButtonBase-root": {
              borderRadius: 1,
              p: 1,

              ":hover": {
                color: ({ palette }) => alpha(palette.onSurface.main, 1),
              },
            },
          }}
          value={tagList.map((value) => createSelectOption(value))}
          size="small"
          fullWidth
          multiple
          freeSolo
          filterOptions={(options, params) => {
            const filtered = createFilterOptions<any>()(options, params);

            const { inputValue } = params;

            const isExisting = options.some(
              (option) => inputValue === option.label,
            );

            if (inputValue && !isExisting) {
              filtered.push({
                inputValue,
                label: `Add "${inputValue}"`,
              });
            }

            return filtered;
          }}
          onChange={(
            event,
            tags: (SelectOptionType | string)[],
            reason,
            details,
          ) => {
            const actionObject = {
              reason,
              option: details?.option,
            };

            handleTagChange(actionObject);
          }}
          options={getTagsOptions()}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                sx={{
                  borderRadius: 0.5,
                  border: ({ palette }) =>
                    `1px solid ${alpha(palette.onSecondary.main, 0.1)}`,
                }}
                label={option.label}
                color="secondary"
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              color="secondary"
              {...(params as any)}
              placeholder="Add Tag..."
            />
          )}
          clearIcon={
            <>
              <Icon sx={{ fontSize: 12, mr: 0.5 }}>clear</Icon>
              <Typography sx={{ fontSize: 12, lineHeight: 1 }}>
                Remove all tags
              </Typography>
            </>
          }
        />

        <ConfirmationModal
          title={confirmModalTitle}
          message={confirmModalMessage}
          open={tagRemoveModalParams.isOpen}
          onDone={() => {
            return handleTagChange(tagRemoveModalParams.actionObject);
          }}
          onClose={() => {
            setTagRemoveModalParams({
              isOpen: false,
              tag: "",
              clearAll: false,
              actionObject: {},
            });
          }}
        />
      </Box>
    </Fade>
  );
};

const InfoSection = ({
  thumbnail,
  imageHandler,
  isInfoOpened,
}: {
  thumbnail: Thumbnail<Image> | undefined;
  imageHandler: ImageHandlerV2 | null;
  isInfoOpened: boolean;
}) => {
  if (!thumbnail || !imageHandler) {
    return <></>;
  }

  const baseProps = {
    sx: {
      wordWrap: "break-word",
      ".MuiListItemText-primary": {
        fontWeight: "bold",
        color: ({ palette }) => palette.secondary.main,
      },
      ".MuiListItemText-secondary": {
        color: ({ palette }) => palette.dark,
      },
    },
  };

  const info = imageHandler.getImageInfo(thumbnail.image);

  return (
    <InfoContainer open={isInfoOpened}>
      <List dense disablePadding>
        {Object.keys(info).map((key, keyIndex) => {
          return (
            <Fragment key={keyIndex}>
              <ListItem>
                <ListItemText
                  primary={key}
                  secondary={info[key]}
                  {...baseProps}
                />
              </ListItem>
              <Divider light />
            </Fragment>
          );
        })}
      </List>
    </InfoContainer>
  );
};

const WeatherInfoSection = ({
  thumbnail,
  imageHandler,
  isWeatherInfoOpened,
}: {
  thumbnail: Thumbnail<Image> | undefined;
  imageHandler: ImageHandlerV2 | null;
  isWeatherInfoOpened: boolean;
}) => {
  const [location, setLocation] = useState<WeatherLocation>();
  const [dateTime, setDateTime] = useState<UnixEpoch>(0);
  const [isShowMore, setIsShowMore] = useState(false);

  const { data, getWeather } = useWeather();

  const navigate = useNavigate();
  const params = useParams();

  const setCurrentWeatherImage = useSetAtom(currentWeatherImageState);

  const xxsmall = useMediaQuery((theme: Theme) => theme.breakpoints.down(300), {
    noSsr: true,
  });

  useEffect(() => {
    if (isWeatherInfoOpened && location) {
      getWeather(location.param, [dateTime], {
        timeZone: location.timeZone,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWeatherInfoOpened, location, dateTime]);

  useEffect(() => {
    if (imageHandler && thumbnail) {
      imageHandler.getWeatherLocation().then((location) => {
        if (location) {
          setLocation(location);

          const moment = momentWithTimeZoneHOF(location.timeZone);

          setDateTime(
            moment(imageHandler.getImageTime(thumbnail.image)).unix(),
          );
        }
      });
    }
  }, [imageHandler, thumbnail]);

  const info = useMemo(() => {
    return data && location
      ? {
          location: location.param,
          weatherCondition: data.currentConditions.conditions,
          temp: <>{data.currentConditions.temp} &#8451;</>,
          feelsLike: <>{data.currentConditions.feelslike} &#8451;</>,
          humidity: <>{data.currentConditions.humidity}%</>,
          precipitation: <>{data.currentConditions.precip} mm</>,
          pressure: <>{data.currentConditions.pressure} Mb</>,
          windspeed: <>{data.currentConditions.windspeed} Km/h</>,
          solar: (
            <>
              {(data.currentConditions.solarenergy * 277.78).toFixed(2)} Wh/m²
            </>
          ),
          sunrise: moment
            .unix(data.currentConditions.sunriseEpoch)
            .format("hh:mm:ss A"),
          sunset: moment
            .unix(data.currentConditions.sunsetEpoch)
            .format("hh:mm:ss A"),
          cloudCover: <>{data.currentConditions.cloudcover}%</>,
          uv: <>{data.currentConditions.uvindex}</>,
          weatherTime: (
            <>
              {moment
                .unix(data.currentConditions.datetimeEpoch)
                .tz(location.timeZone)
                .format("YYYY-MM-DD HH:mm Z z")}
            </>
          ),
        }
      : {
          location: "-",
          weatherCondition: "-",
          temp: "-",
          feelsLike: "-",
          humidity: "-",
          precipitation: "-",
          pressure: "-",
          windspeed: "-",
          solar: "-",
          sunrise: "-",
          sunset: "-",
          cloudCover: "-",
          uv: "-",
          weatherTime: "-",
        };
  }, [data, location]);

  const [weatherReportComponentCahces, setWeatherReportComponentCahces] =
    useAtom(weatherReportComponentCahcesState);

  return (
    <Fade in={isWeatherInfoOpened} mountOnEnter unmountOnExit>
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          right: 0,
          minWidth: { xs: "100%", sm: "50%", md: "25%", lg: "20%" },
          width: "max-content",
          zIndex: 10,
          maxHeight: ({ customConfig }) =>
            `calc(100vh - ${customConfig.imageViewerNavbarHeight} - ${customConfig.imageViewerNavbarHeight} )`,

          overflow: "auto",
        }}
      >
        <Box
          sx={{
            height: "100%",
            backgroundColor: ({ palette }) => palette.secondary.dark,
            color: ({ palette }) => palette.onSecondary.main,

            opacity: {
              xs: 1,
              sm: 0.8,
            },
            transition: ({ transitions }) =>
              `${transitions.create("all")} !important`,

            ":hover": {
              opacity: 1,
            },

            borderRadius: 1,
            boxShadow: 1,

            p: 2,
            border: ({ palette }) =>
              `1px solid ${alpha(palette.onSecondary.main, 0.8)}`,
          }}
        >
          <Box
            sx={{
              borderRadius: 1,
              boxShadow: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 2,
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              {data?.currentConditions.icon && (
                <FontAwesomeSvgIcon
                  icon={getFaWeatherIcon(
                    data?.currentConditions.icon as FaWeatherIconName,
                  )}
                  sx={{
                    fontSize: 60,
                  }}
                />
              )}

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: 0.5,
                }}
              >
                <Typography
                  sx={{ fontSize: 12, fontWeight: "bold", lineHeight: 1 }}
                >
                  {info.weatherCondition}
                </Typography>

                <Typography
                  sx={{ fontSize: 22, fontWeight: "bold", lineHeight: 1 }}
                >
                  {info.temp}
                </Typography>

                <Typography
                  sx={{ fontSize: 8, fontWeight: "bold", lineHeight: 1 }}
                >
                  {info.location}
                </Typography>
              </Box>

              <Box
                sx={{
                  ml: "auto",
                  display: "flex",
                  alignItems: "flex-start",
                  alignSelf: "flex-start",
                  cursor: "pointer",
                }}
              >
                <OpenInNew
                  sx={{ fontSize: 14 }}
                  onClick={() => {
                    setCurrentWeatherImage(thumbnail!.image);

                    const newWeatherReportComponentCahces = {
                      ...weatherReportComponentCahces,
                    };

                    delete newWeatherReportComponentCahces[params.id as string];

                    setWeatherReportComponentCahces(
                      newWeatherReportComponentCahces,
                    );

                    navigate("./weather");
                  }}
                />
              </Box>
            </Box>

            <Box
              sx={{
                backgroundColor: ({ palette }) => palette.secondary.main,
                borderRadius: 2,

                mt: 2,
                p: 1,
              }}
            >
              <Box
                sx={({ breakpoints }) => ({
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  justifyContent: "space-between",
                  flexDirection: xxsmall ? "column" : "row",
                })}
              >
                {[
                  {
                    label: info.windspeed,
                    icon: getFaWeatherIcon("wind"),
                    title: "Wind speed",
                  },
                  {
                    label: info.precipitation,
                    icon: faDroplet,
                    title: "Precipitation",
                  },
                  {
                    label: info.solar,
                    icon: getFaWeatherIcon("clear-day"),
                    title: "Solar Energy",
                  },
                ].map((info, infoIndex) => {
                  return (
                    <Fragment key={infoIndex}>
                      {infoIndex !== 0 && (
                        <Divider
                          orientation={xxsmall ? "horizontal" : "vertical"}
                          flexItem
                          sx={{
                            mx: 1,
                            backgroundColor: ({ palette }) =>
                              alpha(palette.onSecondary.main, 0.5),
                          }}
                        />
                      )}

                      <Tooltip title={info.title}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexWrap: { xs: "wrap", sm: "nowrap" },
                            gap: 1,
                            width: "100%",
                            flex: 1,
                          }}
                        >
                          <FontAwesomeSvgIcon
                            icon={info.icon}
                            sx={{
                              fontSize: 14,
                            }}
                          />
                          <Typography sx={{ fontSize: 12, textWrap: "nowrap" }}>
                            {info.label}
                          </Typography>
                        </Box>
                      </Tooltip>
                    </Fragment>
                  );
                })}
              </Box>

              {isShowMore && (
                <Box>
                  <Divider
                    sx={{
                      backgroundColor: ({ palette }) =>
                        alpha(palette.onSecondary.main, 0.5),
                      my: 1,
                    }}
                  />

                  {[
                    [
                      { label: "Pressure", value: info.pressure },
                      { label: "Humidity", value: info.humidity },
                    ],
                    [
                      { label: "Sunrise", value: info.sunrise },
                      { label: "Sunset", value: info.sunset },
                    ],
                    [
                      { label: "Cloud Cover", value: info["cloudCover"] },
                      { label: "UV", value: info["uv"] },
                    ],
                    [{ label: "Weather Time", value: info["weatherTime"] }],
                  ].map((row, rowIndex) => {
                    return (
                      <Fragment key={rowIndex}>
                        {rowIndex > 0 && (
                          <Divider
                            sx={{
                              backgroundColor: ({ palette }) =>
                                alpha(palette.onSecondary.main, 0.5),
                              my: 1,
                            }}
                          />
                        )}

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          {row.map((col, colIndex) => {
                            return (
                              <Box
                                key={colIndex}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                  flex: 1,
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: 10,
                                    lineHeight: 1,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {col.label}
                                </Typography>

                                <Typography
                                  sx={{
                                    fontSize: 12,

                                    lineHeight: 1,
                                    mt: 0.75,
                                  }}
                                >
                                  {col.value}
                                </Typography>
                              </Box>
                            );
                          })}
                        </Box>
                      </Fragment>
                    );
                  })}
                </Box>
              )}
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 1,
              }}
            >
              <Button
                variant="contained"
                fullWidth
                size="small"
                sx={{ p: 0 }}
                color="secondary"
                disableElevation
                onClick={() => {
                  setIsShowMore(!isShowMore);
                }}
              >
                {isShowMore ? (
                  <KeyboardArrowUp fontSize="small" />
                ) : (
                  <KeyboardArrowDownIcon fontSize="small" />
                )}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Fade>
  );
};

const DownloadMenuSection = ({
  anchorEl,
  handleClose,
  imageHandler,
  thumbnail,
  trackAction,
}: {
  imageHandler: ImageHandlerV2 | null;
  trackAction: TrackImageViewerAction;
  [key: string]: any;
}) => {
  const { getWeather } = useWeather();

  const handleDownload = async (downloadType: "0" | "1" | "2" = "0") => {
    const isOriginal = downloadType === "0";
    const isWithTimeStamp = downloadType === "1";
    const isWithWeatherInfo = downloadType === "2";

    try {
      if (imageHandler && thumbnail) {
        switch (imageHandler?.hostUsed) {
          case "0": {
            const typeThumbnail = thumbnail as Thumbnail<FirebaseImage>;
            const imageData = await fetch(thumbnail.fulRes);
            const imageBlob = await imageData.blob();
            const fileName = typeThumbnail.image.fileName;

            if (isOriginal) {
              const downloadUrl = URL.createObjectURL(imageBlob);
              const a = document.createElement("a");

              a.href = downloadUrl;
              a.download = fileName;

              a.click();
            } else if (isWithTimeStamp) {
              await downloadWithTimeStamp(imageBlob, fileName);
            } else if (isWithWeatherInfo) {
              await downloadWithWeatherInfo(imageBlob, fileName);
            }

            break;
          }

          case "1":
          default: {
            const typeThumbnail = thumbnail as Thumbnail<DropBoxImage>;

            if (isOriginal) {
              thumbnail.file?.click();
            } else if (isWithTimeStamp) {
              await downloadWithTimeStamp(
                typeThumbnail.fullResBlob as Blob,
                typeThumbnail.image.name,
              );
            } else if (isWithWeatherInfo) {
              await downloadWithWeatherInfo(
                typeThumbnail.fullResBlob as Blob,
                typeThumbnail.image.name,
              );
            }

            break;
          }
        }
      }
    } catch (err) {
      console.error(err);
    }

    trackAction(
      isOriginal
        ? "download original"
        : isWithTimeStamp
        ? "download with timestamp"
        : "download with weather info",
    );
  };

  const downloadWithTimeStamp = async (imageBlob: Blob, fileName: string) => {
    if (imageHandler && imageBlob) {
      await downloadImageWithTexts(imageBlob, fileName, [
        moment(imageHandler.getImageTime(thumbnail.image)).format(
          "YYYY-MM-DD hh:mm:ss A",
        ),
      ]);
    }
  };

  const downloadImageWithTexts = async (
    imageBlob: Blob,
    fileName: string,
    texts: string[],
  ) => {
    if (imageHandler && imageBlob) {
      const canvas = document.createElement("canvas");
      const div = document.createElement("div");
      const img = document.createElement("img");

      const imageBlobUrl = URL.createObjectURL(imageBlob);

      div.style.fontFamily = "Kode Mono";

      img.crossOrigin = "anonymous";
      img.src = imageBlobUrl;

      img.onload = async () => {
        canvas.width = img.width;
        canvas.height = img.height;

        const ctx = canvas.getContext("2d");

        if (ctx) {
          ctx!.drawImage(img, 0, 0);

          // set ratio based on the canvas size, adjust as needed
          const fontSizeRatio = canvas.width / 1000;
          const fontSize = 12 * fontSizeRatio;

          const fontFace = new FontFace(
            "Kode Mono",
            `url(${process.env.PUBLIC_URL}/assets/fonts/KodeMono-VariableFont_wght.ttf)`,
          );

          await fontFace.load().then((font) => {
            document.fonts.add(font);
          });

          const renderText = (ctx, text, mt = 0) => {
            ctx.font = `${fontSize}px 'Kode Mono'`;

            const textMetrics = ctx.measureText(text);
            const textWidth = textMetrics.width;
            const textHeight =
              textMetrics.fontBoundingBoxAscent +
              textMetrics.fontBoundingBoxDescent;

            const paddingX = 30 * fontSizeRatio;
            const paddingY = 15 * fontSizeRatio;

            const margin = canvas.width * (3 / 100);

            const x = margin;
            const y = canvas.height - mt - margin - textHeight - paddingY / 2;

            ctx.fillStyle = "rgba(0, 0, 0, 0.5)";
            ctx.fillRect(x, y, textWidth + paddingX, textHeight + paddingY);

            ctx.fillStyle = "rgba(255, 255, 255, 1)";
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            ctx.fillText(
              text,
              x + textWidth / 2 + paddingX / 2,
              y + textHeight / 2 + paddingY / 2,
            );
          };

          texts.reverse().forEach((text, textIndex) => {
            renderText(ctx, text, textIndex * 30 * fontSizeRatio);
          });

          const watermarkedImageUrl = canvas.toDataURL("image/jpeg");

          const reader = new FileReader();

          reader.readAsDataURL(imageBlob);

          reader.onload = function () {
            const dataUrl = reader.result;

            const exifObj = piexif.load(dataUrl);

            const exifStr = piexif.dump(exifObj);

            const url = piexif.insert(exifStr, watermarkedImageUrl);

            const link = document.createElement("a");
            link.href = url;
            link.download = fileName;

            link.click();
          };
        }
      };
    }
  };

  const downloadWithWeatherInfo = async (imageBlob: Blob, fileName: string) => {
    if (imageHandler && thumbnail) {
      const location = await imageHandler.getWeatherLocation();

      const dateTime = moment(
        imageHandler.getImageTime(thumbnail.image),
      ).unix();

      const weatherData = await getWeather(location.param, [dateTime], {
        timeZone: location.timeZone,
      });

      if (weatherData) {
        const info = {
          condition: weatherData.currentConditions.conditions,
          temp: `${weatherData.currentConditions.temp}°C`,
          humidity: `${weatherData.currentConditions.humidity}%`,
          precipitation: `${weatherData.currentConditions.precip} mm`,
          pressure: `${weatherData.currentConditions.pressure} Mb`,
          windspeed: `${weatherData.currentConditions.windspeed} Km/h`,
          solar: `${(
            weatherData.currentConditions.solarenergy * 277.78
          ).toFixed(2)} Wh/m²`,
        };

        await downloadImageWithTexts(imageBlob, fileName, [
          `${info.condition} | ${moment(
            imageHandler.getImageTime(thumbnail.image),
          ).format("YYYY-MM-DD hh:mm:ss A")}`,
          `Temp: ${info.temp} | Wind: ${info.windspeed} | Precip: ${info.precipitation} | Solar: ${info.solar}`,
        ]);
      }
    }
  };

  const menuItem = [
    {
      label: "Original Image",
      onClick: () => handleDownload("0"),
    },
    {
      label: "With Timestamp",
      onClick: () => handleDownload("1"),
    },
    {
      label: "With Weather Info",
      onClick: () => handleDownload("2"),
    },
  ];

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      sx={{
        ".MuiMenu-paper": {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          boxShadow: 3,
        },
      }}
    >
      <MenuList disablePadding dense>
        {menuItem.map((item, itemindex) => {
          return (
            <MenuItem
              key={itemindex}
              dense
              onClick={() => {
                item.onClick();
                handleClose();
              }}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <DownloadIcon sx={{ fontSize: "16px !important" }} />
              </Box>

              <Box sx={{ display: "flex", alignItems: "center" }}>
                {item.label}
              </Box>
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
};

const CalendarPicker = memo(
  ({
    isCalenderOpened,
    setIsCalenderOpened,
    handleDateChange,
    currentDate,
    imageHandler,
    isOverlay = false,
  }: {
    isCalenderOpened: boolean;
    setIsCalenderOpened: (isCalenderOpened: boolean) => void;
    handleDateChange: (date: UnixEpoch) => void;
    currentDate: UnixEpoch;
    imageHandler: ImageHandlerV2;
    isOverlay?: boolean;
  }) => {
    return (
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={(e) => {
          e.stopPropagation();
          e.preventDefault();

          // hacky fix to prevent the modal opening again if user clicked on the button which open the modal, onClickAway fired first and change the state which then get change back again from the button
          setTimeout(() => {
            setIsCalenderOpened(false);
          }, 100);
        }}
      >
        <Fade in={isCalenderOpened} unmountOnExit mountOnEnter>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: "50%",
              transform: "translate(-50%, -100%)",
              backgroundColor: ({ palette }) => palette.surface.main,
              color: ({ palette }) => palette.onSurface.main,
              boxShadow: 3,
              borderRadius: 0.5,

              ".MuiPickersArrowSwitcher-button, .MuiPickersCalendarHeader-switchViewButton":
                {
                  color: ({ palette }) => palette.secondary.main,

                  "&.Mui-disabled": {
                    color: ({ palette }) => alpha(palette.secondary.main, 0.4),
                  },
                },

              ".MuiButtonBase-root.Mui-selected, .MuiPickersYear-yearButton.Mui-selected":
                {
                  backgroundColor: ({ palette }) =>
                    isOverlay
                      ? getContrastShade(palette.primary, "dark")
                      : palette.secondary.main,
                  color: ({ palette }) =>
                    isOverlay
                      ? palette.onPrimary.main
                      : palette.onSecondary.main,
                },
            }}
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DateCalendar
                // slots={{
                //   day: CustomPickersDay,
                // }}
                onChange={(date, state) => {
                  if (state === "finish" && date) {
                    handleDateChange(date.startOf("days").unix());
                    setIsCalenderOpened(!isCalenderOpened);
                  }
                }}
                value={moment.unix(currentDate)}
                minDate={moment.unix(imageHandler?.dateRange[0])}
                maxDate={moment.unix(imageHandler?.dateRange[1])}
              />
            </LocalizationProvider>
          </Box>
        </Fade>
      </ClickAwayListener>
    );
  },
);

export const TopToolBar = ({
  buttons,
  imageHandler,
  tracker = () => {},
}: {
  buttons: any[];
  imageHandler: ImageHandlerV2 | null;
  tracker?: TrackImageViewerAction | TimelapsePlayerTrackAction;
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [dropdownOptions, setDropdownOptions] = useState<
    (DeviceV2 | GalleryV2)[]
  >([]);
  const [searchInput, setSearchInput] = useState<string>("");
  const [galleryJobSiteLookup, setGalleryJobSiteLookup] = useState<{
    [key: number]: JobSite;
  }>({});

  const setCurrentGalleryJobsite = useSetAtom(currentGalleryJobsiteState);
  const setCurrentGalleryClientState = useSetAtom(currentGalleryClientState);

  const clientList = useAtomValue(clientListCacheState);

  const currentUserRole = useAtomValue(currentUserRoleState);

  const setIsSidenavShow = useSetAtom(isSidenavShowState);

  const getDisplayOptions = () => {
    if (searchInput) {
      const searchTexts = _.toLower(searchInput).split(" ");

      return dropdownOptions.filter((device) => {
        const siteName =
          _.toLower(galleryJobSiteLookup[device.id as number]?.name) || "";

        const fields = imageHandler?.isDevice
          ? [
              (device as DeviceV2).friendlyName?.toLocaleLowerCase(),
              (device as DeviceV2).deviceId?.toLocaleLowerCase(),
            ]
          : [
              (device as GalleryV2).galleryName?.toLowerCase(),
              siteName.toLowerCase(),
            ];

        return searchTexts.every((s) => {
          return fields.some((f) => {
            return f.includes(s);
          });
        });
      });
    } else {
      return dropdownOptions;
    }
  };

  const isDevicesMenuOpened = Boolean(anchorEl);

  const { isAdmin } = useAccess();

  const [galleryListCache, setGalleryListCache] = useAtom(
    galleryListCacheState,
  );
  const [deviceListCache, setDeviceListCache] = useAtom(deviceListCacheState);

  const [galleryJobSiteLookupCache, setGalleryJobSiteLookupCache] = useAtom(
    galleryJobSiteLookupCacheState,
  );

  useEffect(() => {
    initDropdown();
  }, [imageHandler]);

  const initDropdown = async () => {
    if (imageHandler) {
      const firebaseController = getFirebaseController();

      let dropdownData: (DeviceV2 | GalleryV2)[] = [];

      if (imageHandler.isDevice) {
        dropdownData = await (deviceListCache[0]
          ? deviceListCache
          : firebaseController.Device.getDevices());
      } else {
        dropdownData = await (galleryListCache[0]
          ? galleryListCache
          : firebaseController.Gallery.getGalleries({
              jobSiteIds: isAdmin
                ? undefined
                : currentUserRole?.accessableJobSites || [],
              isActive: isAdmin ? undefined : true,
              isArchived: isAdmin ? undefined : false,
              isVisible: isAdmin ? undefined : true,
            }));
      }

      if (dropdownData.length > 0) {
        const jobSitesPromises: Promise<any>[] = [];
        const galleryJobSiteLookup: { [key: number]: JobSite } = {};

        if (
          !imageHandler.isDevice &&
          Object.keys(galleryJobSiteLookup).length === 0
        ) {
          // get gallery jobsite

          const galleries = dropdownData as GalleryV2[];

          // TODO: optimize
          galleries.forEach((gallery) => {
            const jobSiteCache =
              galleryJobSiteLookupCache[gallery.id as number];

            if (jobSiteCache) {
              galleryJobSiteLookup[gallery.id as number] = jobSiteCache;
            } else {
              jobSitesPromises.push(
                firebaseController.JobSite.getJobSite(gallery.jobSite).then(
                  (jobsite) => {
                    if (jobsite) {
                      galleryJobSiteLookup[gallery.id as number] = jobsite;
                    }
                  },
                ),
              );
            }
          });
        }

        Promise.all(jobSitesPromises).then(() => {
          setGalleryJobSiteLookup(galleryJobSiteLookup);
          setGalleryJobSiteLookupCache((state) => {
            return {
              ...state,
              ...galleryJobSiteLookup,
            };
          });

          const jobSite = galleryJobSiteLookup[Number(params.id)];

          if (jobSite) {
            setCurrentGalleryJobsite(jobSite);

            const client = clientList.find((client) => {
              // get primary client

              return jobSite.associatedClients[0] === client.id;
            });

            setCurrentGalleryClientState(client || null);
          } else {
            setCurrentGalleryJobsite(null);
          }

          if (imageHandler.isDevice) {
            const sortedDevices = orderByIgnoreCase(
              dropdownData,
              "friendlyName",
              "asc",
            );

            setDropdownOptions(sortedDevices);
          } else {
            const sortedGalleries = _.orderBy(
              dropdownData,
              [
                (gallery) =>
                  galleryJobSiteLookup[
                    gallery.id as number
                  ]?.name?.toLowerCase() || "-",
                "galleryName",
              ],
              ["asc", "asc"],
            );

            setDropdownOptions(
              sortedGalleries.filter(
                (gallery) => !!galleryJobSiteLookup[gallery.id as number],
              ),
            );
          }
        });
      }
    }
  };

  const jobsite = galleryJobSiteLookup[Number(params.id)];

  const smallScreenAndUp = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("sm"),
  );

  const mediumScreenAndUp = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("md"),
  );

  const largeScreenAndUp = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("lg"),
  );

  const renderButtons = useMemo(() => {
    const showMoreButton = {
      name: "more",
      description: "More settings",
      icon: <MoreHorizIcon />,
      onClick: (e) => {
        setToolsMenuAnchorEl(e.currentTarget);
      },
    };

    let dockButtons: any[] = [];
    let menuButtons: any[] = [];

    if (largeScreenAndUp) {
      dockButtons = [...buttons];
      menuButtons = [];
    } else if (mediumScreenAndUp) {
      const partitionIndex = 7;

      dockButtons = [..._.take(buttons, partitionIndex)];
      menuButtons = _.drop(buttons, partitionIndex);
    } else if (smallScreenAndUp) {
      const partitionIndex = 3;

      dockButtons = [..._.take(buttons, partitionIndex)];
      menuButtons = _.drop(buttons, partitionIndex);
    } else {
      dockButtons = [];
      menuButtons = buttons;
    }

    if (menuButtons.length > 0 && !largeScreenAndUp) {
      dockButtons.push(showMoreButton);
    }

    return [dockButtons, menuButtons];
  }, [smallScreenAndUp, mediumScreenAndUp, largeScreenAndUp, buttons]);

  const [toolsMenuAnchorEl, setToolsMenuAnchorEl] =
    useState<null | HTMLElement>(null);

  const trackSearch = useMemo(
    () => _.debounce(() => tracker("item search"), 1000),
    [tracker],
  );

  const themeSettings = useAtomValue(themeSettingsState);

  const logo = useMemo(() => {
    return themeSettings?.logoUrl || ReliveItLogo;
  }, [themeSettings]);

  return (
    <Box
      id="top-tool-bar-container"
      sx={{
        position: "fixed",
        top: 0,
        borderBottom: ({ palette }) =>
          `1px solid ${alpha(palette.onSecondary.main, 0.3)}`,
        width: "100%",
        backgroundColor: ({ palette }) => palette.secondary.main,
        height: ({ customConfig }) => customConfig.imageViewerNavbarHeight,
        zIndex: ({ zIndex }) => zIndex.appBar,
        boxShadow: 1,
      }}
    >
      <Grid container alignItems={"center"} height={"100%"} id="top-tool-bar">
        <Grid
          item
          xs={1}
          sm={3}
          md={4}
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box sx={{ mx: 2 }}>
            <IconButton
              color="inherit"
              disableRipple
              sx={{ p: 0 }}
              onClick={() => setIsSidenavShow((prev) => !prev)}
            >
              <MenuIcon fontSize="medium" />
            </IconButton>
          </Box>

          {smallScreenAndUp && (
            <Box
              sx={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                component={Link}
                to={"/"}
                sx={{
                  maxWidth: 80,
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  crossOrigin="anonymous"
                  component={"img"}
                  width={"100%"}
                  height={"100%"}
                  src={logo}
                  sx={{
                    cursor: "pointer",
                    objectFit: "contain",
                  }}
                />
              </Box>
            </Box>
          )}
        </Grid>

        <Grid
          item
          xs={10}
          sm={6}
          md={4}
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
              tracker("item toggle");
            }}
            sx={{
              cursor: "pointer",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: ({ palette }) =>
                isDevicesMenuOpened
                  ? palette.primary.main
                  : alpha(palette.onSecondary.main, 0.8),
              transition: ({ transitions }) => transitions.create("all"),

              ".MuiIconButton-root": {
                color: ({ palette }) =>
                  isDevicesMenuOpened
                    ? palette.primary.main
                    : alpha(palette.onSecondary.main, 0.8),
              },

              ":hover": {
                color: ({ palette }) => alpha(palette.onSecondary.main, 1),

                "& .MuiIconButton-root": {
                  color: ({ palette }) => alpha(palette.onSecondary.main, 1),
                },
              },
            }}
          >
            <Typography
              sx={{
                fontSize: 18,
                fontWeight: "bold",
                lineHeight: 1,
              }}
            >
              {_.truncate(
                `${jobsite?.name ? jobsite?.name + " - " : ""}${
                  imageHandler?.getCurrentObjectName() || "-"
                }`,
                { length: smallScreenAndUp ? 40 : 25 },
              )}
            </Typography>

            <IconButton color="inherit" disableRipple sx={{ p: 0, pl: 1 }}>
              <KeyboardArrowDownIcon fontSize="small" />
            </IconButton>
          </Box>

          <Menu
            anchorEl={anchorEl}
            open={isDevicesMenuOpened}
            onClose={() => setAnchorEl(null)}
            MenuListProps={{
              "aria-labelledby": "lock-button",
              role: "listbox",
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            sx={{
              ".MuiMenu-paper": {
                minWidth: 250,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                boxShadow: 3,
                overflow: "hidden",
              },
            }}
          >
            <Box sx={{ px: 1 }}>
              <TextField
                size="small"
                fullWidth
                placeholder="Search"
                value={searchInput}
                onChange={(e) => {
                  setSearchInput(e.target.value);

                  trackSearch();
                }}
              />

              <Divider sx={{ mt: 1 }} />
            </Box>

            <Box sx={{ maxHeight: 450, overflow: "auto" }}>
              {getDisplayOptions().map((device) => {
                const jobsite = galleryJobSiteLookup[device.id as number];

                return (
                  <MenuItem
                    key={device.id}
                    selected={device.id === imageHandler?.currentObject.id}
                    onClick={() => {
                      if (device.id) {
                        navigate(
                          location.pathname.replace(
                            params.id as string,
                            device.id.toString(),
                          ),
                        );
                        setAnchorEl(null);

                        if (jobsite) {
                          setCurrentGalleryJobsite(jobsite);
                        }
                      }
                    }}
                  >
                    {imageHandler?.isDevice
                      ? (device as DeviceV2).friendlyName ||
                        (device as DeviceV2).deviceId
                      : `${jobsite?.name ? jobsite?.name + " - " : ""}${
                          (device as GalleryV2).galleryName
                        }`}
                  </MenuItem>
                );
              })}
            </Box>
          </Menu>
        </Grid>

        <Grid item xs={1} sm={3} md={4} height="100%">
          <Grid
            container
            justifyContent={"flex-end"}
            height="100%"
            flexWrap={"nowrap"}
          >
            {renderButtons[0].map((button, buttonIndex) => {
              if (button.isBlocked) {
                return <Fragment key={button.name}></Fragment>;
              }

              return (
                <Tooltip title={button.description} key={button.name}>
                  <Grid
                    item
                    onClick={(e) =>
                      !button.isDisabled ? button.onClick(e) : null
                    }
                    sx={{
                      width: "44px",
                      borderLeft: ({ palette }) =>
                        `1px solid ${alpha(palette.onSecondary.main, 0.3)}`,
                      borderTop: "0",
                      borderBottom: "0",
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      pointerEvents: button.isDisabled ? "none" : "auto",
                      px: 2,
                      backgroundColor: ({ palette }) =>
                        button.isActive ? palette.primary.main : "inherit",

                      transition: ({ transitions }) =>
                        transitions.create("all"),

                      ":hover": {
                        backgroundColor: ({ palette }) =>
                          button.isActive ? palette.primary.main : "inherit",

                        transition: ({ transitions }) =>
                          transitions.create("all"),

                        ":hover": {
                          backgroundColor: ({ palette }) =>
                            getContrastShade(palette.secondary, "light"),

                          "& .MuiIconButton-root": {
                            color: ({ palette }) => palette.onSecondary.main,
                          },
                        },

                        ".MuiIconButton-root": {
                          color: ({ palette }) =>
                            button.isActive
                              ? palette.onPrimary.main
                              : palette.onSecondary.main,
                        },
                      },
                    }}
                  >
                    <IconButton
                      color="inherit"
                      disableRipple
                      sx={{ p: 0 }}
                      disabled={button.isDisabled}
                    >
                      {button.icon}
                    </IconButton>
                  </Grid>
                </Tooltip>
              );
            })}
          </Grid>
        </Grid>

        {renderButtons[1].length > 0 && (
          <Menu
            anchorEl={toolsMenuAnchorEl}
            open={Boolean(toolsMenuAnchorEl)}
            onClose={() => setToolsMenuAnchorEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {renderButtons[1].map((button, buttonIndex) => {
              if (button.isBlocked) {
                return <Fragment key={button.name}></Fragment>;
              }

              return [
                <MenuItem
                  key={buttonIndex}
                  disabled={button.isDisabled}
                  dense
                  onClick={(e) => {
                    if (!button.isDisabled) {
                      button.onClick(e);
                    }

                    setToolsMenuAnchorEl(null);
                  }}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    py: 0,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",

                      ".MuiSvgIcon-root": {
                        fontSize: "16px !important",
                      },
                    }}
                  >
                    {button.icon}
                  </Box>

                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {button.description}
                  </Box>
                </MenuItem>,
                button.divider && <Divider />,
              ];
            })}
          </Menu>
        )}
      </Grid>
    </Box>
  );
};

const SliderThumbnail = ({
  thumbnail,
  thumbnailIndex,
  isSelected,
  imageHandler,
  onClick,
  isOverlay,
}) => {
  const [thumbnailSrc, setThumbnailSrc] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const getThumbnail = async (isOverlay = false) => {
    if (imageHandler) {
      const thumbnails = isOverlay
        ? imageHandler.overlayThumbnails
        : imageHandler.thumbnails;
      const thumbnail = thumbnails[thumbnailIndex];

      if (thumbnail) {
        setIsLoading(true);

        const currentImage = await imageHandler.findThumbnail(thumbnail);

        if (currentImage.event) {
          await currentImage.event;
        }

        setThumbnailSrc(thumbnail.thumbnail);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    getThumbnail(isOverlay);
    // eslint-disable-next-line react-hooks/exhaustive-deps

    return () => {
      setThumbnailSrc("");
      setIsLoaded(false);
    };
  }, []);

  return (
    <Grid
      container
      direction={"column"}
      justifyContent={"space-between"}
      height={"100%"}
      flexWrap={"nowrap"}
    >
      {
        <Grid item height={"100%"} sx={{ position: "relative", minHeight: 50 }}>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
              borderRadius: 2,
              visibility: isLoading || !isLoaded ? "visible" : "hidden",
            }}
          >
            <LDSRingLoader size="small" />
          </Box>

          <Box
            key={imageHandler!.getThumbnailImageId(thumbnail).toString()}
            onClick={onClick}
            component={"img"}
            crossOrigin="anonymous"
            src={thumbnailSrc}
            onLoad={() => {
              setIsLoaded(true);
            }}
            sx={{
              display: "block",
              cursor: "pointer",
              width: "100%",
              objectFit: "contain",
              borderRadius: 2,
              border: ({ palette }) =>
                isSelected ? `2px solid ${palette.primary.main}` : "",

              transition: ({ transitions }) => transitions.create("all"),

              ":hover": {
                transform: isSelected ? "" : "scale(1.02)",

                "+ span": {
                  color: ({ palette }) =>
                    isSelected
                      ? palette.primary.main
                      : alpha(palette.onPrimary.main, 0.8),
                },
              },
            }}
          />
        </Grid>
      }

      <Grid item xs={1}>
        <Typography
          variant="caption"
          sx={{
            color: ({ palette }) =>
              isSelected
                ? `${palette.primary.main}`
                : alpha(alpha(palette.onPrimary.main, 0.8), 0.8),
            fontWeight: isSelected ? "bold" : "normal",
            transition: ({ transitions }) => transitions.create("all"),

            mt: 1,
            display: "block",
            lineHeight: 1,
            textAlign: "center",
          }}
        >
          {moment(imageHandler.getImageTime(thumbnail.image)).format("HH:mm A")}
        </Typography>
      </Grid>
    </Grid>
  );
};

const ThumbnailsSlider = ({
  thumbnails,
  currentThumbnailIndex,
  isOverlay = false,
  imageHandler,
  thumbnailOnClick,
}: {
  thumbnails: Thumbnail<Image>[];
  currentThumbnailIndex: number;
  isOverlay?: boolean;
  imageHandler: ImageHandlerV2;
  thumbnailOnClick: (index: number) => void;
}) => {
  const getReverseIndex = useMemo(() => {
    return (index) => {
      return thumbnails.length - 1 - index;
    };
  }, [thumbnails.length]);

  const reversedThumbnails = useMemo(() => {
    return [...thumbnails].reverse();
  }, [thumbnails]);

  const { palette } = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        transform: "translateY(-100%)",

        backgroundColor: ({ palette }) =>
          isOverlay
            ? alpha(getContrastShade(palette.primary, "dark"), 0.2)
            : alpha(getContrastShade(palette.secondary, "light"), 0.2),

        transition: ({ transitions }) => transitions.create("background-color"),

        "&:hover, &:active": {
          backgroundColor: ({ palette }) =>
            isOverlay
              ? alpha(getContrastShade(palette.primary, "dark"), 0.6)
              : alpha(getContrastShade(palette.secondary, "light"), 0.6),
        },

        px: 1,
        overflow: "hidden",
      }}
      draggable="false"
      onTouchStart={(e) => {
        e.currentTarget.style.backgroundColor = isOverlay
          ? alpha(getContrastShade(palette.primary, "dark"), 0.6)
          : alpha(getContrastShade(palette.secondary, "light"), 0.6);
      }}
      onTouchEnd={(e) => {
        e.currentTarget.style.backgroundColor = isOverlay
          ? alpha(getContrastShade(palette.primary, "dark"), 0.2)
          : alpha(getContrastShade(palette.secondary, "light"), 0.2);
      }}
    >
      <Box
        sx={{
          width: "100%",
          overflow: "auto",
          py: 1,
        }}
      >
        <Grid container columnSpacing={1} flexWrap={"nowrap"}>
          {reversedThumbnails.map((thumbnail, thumbnailIndex) => {
            const reversedThumbnailIndex = getReverseIndex(thumbnailIndex);

            return (
              <Grid
                item
                xs={3}
                md={2}
                lg={1.5}
                xl={1}
                flexShrink={0}
                key={imageHandler!.getThumbnailImageId(thumbnail).toString()}
              >
                <SliderThumbnail
                  onClick={() => {
                    thumbnailOnClick(reversedThumbnailIndex);
                  }}
                  thumbnail={thumbnail}
                  thumbnailIndex={reversedThumbnailIndex}
                  isSelected={reversedThumbnailIndex === currentThumbnailIndex}
                  imageHandler={imageHandler}
                  isOverlay={isOverlay}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};

const BottomToolBar = ({
  currentDate,
  currentOverlayDate,
  handleDateChange,
  currentThumbnailIndex,
  overlayThumbnailIndex,
  getThumbnail,
  loadOverlayThumbnail,
  imageHandler,
  setCurrentZoom,
  currentZoom,
  isOverlayEnabled,
  isOverlayLoading,
  setZoomTrigger,
}) => {
  const { trackAction } = useImageViewerTracker(imageHandler, "main");

  const [thumbnails, setThumbnails] = useState<Thumbnail<Image>[]>([]);
  const [overlayThumbnails, setOverlayThumbnails] = useState<
    Thumbnail<Image>[]
  >([]);

  const [isCalenderOpened, setIsCalenderOpened] = useState(false);
  const [isOverlayCalenderOpened, setIsOverlayCalenderOpened] = useState(false);
  const [isSliderOpened, setisSliderOpened] = useState(true);
  const [isOverlaySliderOpened, setIsOverlaySliderOpened] = useState(false);

  const params = useParams();

  const minDate = moment.unix(imageHandler.dateRange[0]).startOf("days").unix();
  const maxDate = moment.unix(imageHandler.dateRange[1]).endOf("days").unix();

  const nextDate = moment
    .unix(currentDate)
    .add(1, "days")
    .startOf("days")
    .unix();

  const prevDate = moment
    .unix(currentDate)
    .subtract(1, "days")
    .startOf("days")
    .unix();

  const nextOverlayDate = moment
    .unix(currentOverlayDate)
    .add(1, "days")
    .startOf("days")
    .unix();

  const prevOverlayDate = moment
    .unix(currentOverlayDate)
    .subtract(1, "days")
    .startOf("days")
    .unix();

  useEffect(() => {
    setThumbnails(imageHandler?.thumbnails || []);
  }, [imageHandler?.thumbnails]);

  useEffect(() => {
    if (!isOverlayEnabled) {
      setIsOverlayCalenderOpened(false);
      setIsOverlaySliderOpened(false);
    } else {
      setOverlayThumbnails(imageHandler?.overlayThumbnails || []);
    }
  }, [isOverlayEnabled, imageHandler?.overlayThumbnails]);

  useEffect(() => {
    setisSliderOpened(false);
    setIsOverlaySliderOpened(false);
    setIsOverlayCalenderOpened(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const smallScreenAndUp = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("sm"),
  );

  const mediumScreenAndUp = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("md"),
  );

  return (
    <Box
      id="bottom-tool-bar-container"
      sx={{
        position: "fixed",
        top: "auto",
        bottom: 0,
        borderTop: ({ palette }) =>
          `1px solid ${alpha(palette.onSecondary.main, 0.3)}`,
        width: "100%",
        boxShadow: 1,
        backgroundColor: ({ palette }) => palette.secondary.main,
        zIndex: ({ zIndex }) => zIndex.appBar,

        display: "flex",
        flexDirection: "column",
      }}
    >
      {isSliderOpened && (
        <Fade in={isSliderOpened}>
          <Box>
            <ThumbnailsSlider
              thumbnails={thumbnails}
              currentThumbnailIndex={currentThumbnailIndex}
              imageHandler={imageHandler}
              thumbnailOnClick={getThumbnail}
            />
          </Box>
        </Fade>
      )}

      {isOverlaySliderOpened && (
        <Fade in={isOverlaySliderOpened}>
          <Box>
            <ThumbnailsSlider
              thumbnails={overlayThumbnails}
              currentThumbnailIndex={overlayThumbnailIndex}
              isOverlay={true}
              imageHandler={imageHandler}
              thumbnailOnClick={loadOverlayThumbnail}
            />
          </Box>
        </Fade>
      )}

      <Grid
        id="bottom-tool-bar"
        container
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{
          flexWrap: "nowrap",
          height: ({ customConfig }) => customConfig.imageViewerNavbarHeight,
          px: {
            xs: 2,
            md: 2,
            lg: 14,
            xl: 20,
          },
        }}
      >
        {mediumScreenAndUp && (
          <Grid
            item
            xs={4}
            display={"flex"}
            sx={{
              pl: {
                sm: 0,
              },
            }}
            height={"100%"}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                disableRipple
                disabled={imageHandler.thumbnails.length < 1}
                onClick={() => {
                  const isOpen = !isSliderOpened;

                  if (isOpen) {
                    setIsOverlaySliderOpened(false);
                    setIsOverlayCalenderOpened(false);

                    trackAction("thumbnails slider");
                  }

                  setisSliderOpened(isOpen);
                }}
                sx={{
                  color: ({ palette }) =>
                    isSliderOpened
                      ? `${palette.onSecondary.main} !important`
                      : "inherit",
                  p: 0,
                }}
              >
                {isOverlayEnabled && (
                  <Typography sx={{ fontSize: 12 }}>A</Typography>
                )}
                <SliderIcon fontSize={"small"} />
              </IconButton>
            </Box>

            {isOverlayEnabled && (
              <Box
                sx={{
                  position: "relative",
                }}
              >
                <Box
                  sx={({ breakpoints }) => ({
                    overflow: "hidden",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",

                    [breakpoints.down(1000)]: {
                      width: 250,
                    },
                  })}
                >
                  <CalendarPicker
                    isCalenderOpened={isOverlayCalenderOpened}
                    setIsCalenderOpened={setIsOverlayCalenderOpened}
                    handleDateChange={(date) => {
                      handleDateChange(date, true);
                    }}
                    imageHandler={imageHandler}
                    currentDate={currentOverlayDate}
                    isOverlay={true}
                  />

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      ml: 1,
                      background: ({ palette }) =>
                        !imageHandler.overlayThumbnails.length &&
                        !isOverlayLoading
                          ? alpha(palette.error.main, 0.6)
                          : alpha(
                              getContrastShade(palette.primary, "dark"),
                              isOverlaySliderOpened || isOverlayCalenderOpened
                                ? 0.6
                                : 0.2,
                            ),
                      transition: ({ transitions }) =>
                        transitions.create("background"),
                      height: "max-content",
                      px: 1,
                      py: 0.5,
                      borderRadius: 2,
                      position: "relative",
                      overflow: "auto",
                      border: ({ palette }) =>
                        `1px solid ${alpha(palette.onSecondary.main, 0.2)}`,
                    }}
                  >
                    <IconButton
                      disableRipple
                      disabled={imageHandler.thumbnails.length < 1}
                      onClick={() => {
                        const isOpen = !isOverlaySliderOpened;

                        if (isOpen) {
                          setisSliderOpened(false);
                          trackAction("thumbnails slider");
                        } else {
                          setIsCalenderOpened(false);
                        }

                        setIsOverlaySliderOpened(isOpen);
                      }}
                      sx={{
                        color: ({ palette }) =>
                          isOverlaySliderOpened
                            ? `${palette.onPrimary.main} !important`
                            : "inherit",
                        p: 0,
                        mr: 1,
                      }}
                    >
                      <Typography sx={{ fontSize: 12 }}>B</Typography>
                      <SliderIcon fontSize={"small"} />
                    </IconButton>

                    <IconButton
                      color="inherit"
                      disableRipple
                      sx={{ p: 0 }}
                      disabled={prevOverlayDate < minDate || isOverlayLoading}
                      onClick={() => {
                        handleDateChange(prevOverlayDate, true);
                        trackAction("previous day bottom");
                      }}
                    >
                      <SkipPreviousIcon sx={{ fontSize: 22 }} />
                    </IconButton>

                    <IconButton
                      size="small"
                      disableRipple
                      sx={{ p: 0 }}
                      disabled={
                        !imageHandler ||
                        imageHandler.overlayThumbnails.length === 0 ||
                        overlayThumbnailIndex ===
                          imageHandler.overlayThumbnails.length - 1
                      }
                      onClick={() => {
                        trackAction("previous image bottom");
                        loadOverlayThumbnail(overlayThumbnailIndex + 1);
                      }}
                    >
                      <NavigateBeforeIcon sx={{ fontSize: 22, ml: -0.5 }} />
                    </IconButton>

                    <IconButton
                      size="small"
                      disableRipple
                      disabled={isOverlayLoading}
                      sx={{
                        p: 0,
                        color: ({ palette }) =>
                          isOverlayCalenderOpened
                            ? `${palette.onPrimary.main} !important`
                            : "inherit",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsOverlayCalenderOpened(!isOverlayCalenderOpened);

                        if (!isOverlayCalenderOpened) {
                          trackAction("date picker");
                        }
                      }}
                    >
                      <CalendarMonthIcon sx={{ fontSize: 14 }} />
                    </IconButton>

                    <Typography
                      variant="caption"
                      lineHeight={1}
                      sx={{
                        maxWidth: "200px",
                        whiteSpace: "noWrap",
                        mx: 0.2,

                        color: ({ palette }) =>
                          isOverlayLoading
                            ? alpha(palette.onPrimary.main, 0.5)
                            : isOverlayCalenderOpened
                            ? `${palette.onPrimary.main} !important`
                            : "inherit",
                        fontWeight: isOverlayCalenderOpened
                          ? "bold !important"
                          : "normal  !important",

                        ":hover": {
                          color: ({ palette }) =>
                            `${palette.onPrimary.main} !important`,
                        },
                      }}
                    >
                      {moment.unix(currentOverlayDate).format("ddd D")}
                      <sup>
                        {moment
                          .unix(currentOverlayDate)
                          .format("Do")
                          .replace(/\d+/, "")}
                      </sup>
                      {moment.unix(currentOverlayDate).format(" MMMM YYYY")}
                      {moment.unix(currentOverlayDate).format(" HH:mm")}
                    </Typography>

                    <IconButton
                      size="small"
                      color="inherit"
                      disableRipple
                      sx={{ p: 0 }}
                      disabled={
                        !imageHandler ||
                        imageHandler.overlayThumbnails.length === 0 ||
                        overlayThumbnailIndex === 0
                      }
                      onClick={() => {
                        loadOverlayThumbnail(overlayThumbnailIndex - 1);
                        trackAction("next image bottom");
                      }}
                    >
                      <NavigateNextIcon sx={{ fontSize: 22, mr: -0.5 }} />
                    </IconButton>

                    <IconButton
                      color="inherit"
                      disableRipple
                      sx={{ p: 0 }}
                      disabled={nextOverlayDate > maxDate || isOverlayLoading}
                      onClick={() => {
                        handleDateChange(nextOverlayDate, true);
                        trackAction("next day bottom");
                      }}
                    >
                      <SkipNextIcon sx={{ fontSize: 22 }} />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            )}
          </Grid>
        )}

        <Grid
          item
          xs={12}
          md={"auto"}
          sx={{
            position: "relative",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: {
              xs: "space-between",
              sm: "center",
            },
          }}
        >
          <Tooltip title="Previous Day">
            <span>
              <IconButton
                color="inherit"
                disableRipple
                sx={{
                  p: 0,
                }}
                disabled={prevDate < minDate}
                onClick={() => {
                  trackAction("previous day bottom");
                  handleDateChange(prevDate);
                }}
              >
                <SkipPreviousIcon fontSize="large" />
              </IconButton>
            </span>
          </Tooltip>

          <Tooltip title="Previous Image">
            <span>
              <IconButton
                color="inherit"
                disableRipple
                sx={{
                  p: 0,
                  mx: -0.5,
                }}
                disabled={
                  !imageHandler ||
                  imageHandler.thumbnails.length === 0 ||
                  currentThumbnailIndex === imageHandler.thumbnails.length - 1
                }
                onClick={() => {
                  trackAction("previous image bottom");
                  getThumbnail(currentThumbnailIndex + 1);
                }}
              >
                <NavigateBeforeIcon fontSize="large" />
              </IconButton>
            </span>
          </Tooltip>

          <CalendarPicker
            isCalenderOpened={isCalenderOpened}
            setIsCalenderOpened={setIsCalenderOpened}
            handleDateChange={(date) => {
              handleDateChange(date, null, false);
            }}
            imageHandler={imageHandler}
            currentDate={currentDate}
          />

          {smallScreenAndUp && (
            <IconButton
              disableRipple
              sx={{
                p: 0,
                ml: 1,
                color: ({ palette }) =>
                  isCalenderOpened
                    ? `${palette.onSecondary.main} !important`
                    : "inherit",
              }}
              onClick={(e) => {
                e.stopPropagation();
                setIsCalenderOpened(!isCalenderOpened);

                if (!isCalenderOpened) {
                  trackAction("date picker");
                }
              }}
            >
              <CalendarMonthIcon sx={{ fontSize: "18px" }} />
            </IconButton>
          )}

          <Box
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();

              setIsCalenderOpened(!isCalenderOpened);

              if (!isCalenderOpened) {
                trackAction("date picker");
              }
            }}
            sx={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              cursor: "pointer !important",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                ml: 1,
                mr: 2,
                textAlign: "center",
                lineHeight: 1,
                fontSize: {
                  xs: 12,
                  sm: 14,
                  md: 16,
                },
              }}
            >
              {moment.unix(currentDate).format("ddd D")}
              <sup>
                {moment.unix(currentDate).format("Do").replace(/\d+/, "")}
              </sup>
              {moment.unix(currentDate).format(" MMMM YYYY")}
              {moment.unix(currentDate).format(" HH:mm")}
            </Typography>
          </Box>

          <Tooltip title="Next image">
            <span>
              <IconButton
                size="small"
                color="inherit"
                disableRipple
                sx={{
                  p: 0,
                  mx: -0.5,
                }}
                onClick={() => {
                  trackAction("next image bottom");
                  getThumbnail(currentThumbnailIndex - 1);
                }}
                disabled={
                  !imageHandler ||
                  imageHandler.thumbnails.length === 0 ||
                  currentThumbnailIndex === 0
                }
              >
                <NavigateNextIcon fontSize="large" />
              </IconButton>
            </span>
          </Tooltip>

          <Tooltip title="Next Day">
            <span>
              <IconButton
                size="small"
                color="inherit"
                disableRipple
                sx={{
                  p: 0,
                }}
                disabled={nextDate > maxDate}
                onClick={() => {
                  trackAction("next day bottom");
                  handleDateChange(nextDate);
                }}
              >
                <SkipNextIcon fontSize="large" />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>

        {mediumScreenAndUp && (
          <Grid item xs={4} display={"flex"}>
            <Stack
              spacing={2}
              direction="row"
              alignItems="center"
              width={"100%"}
              sx={{
                pl: {
                  xs: 0,
                  sm: 2,
                  md: 10,
                  lg: 16,
                },
              }}
            >
              <IconButton
                disableRipple
                sx={{ p: 0 }}
                onClick={() => {
                  setCurrentZoom(currentZoom - 1);
                  setZoomTrigger((prev) => !prev);
                  trackAction("zoom slider");
                }}
              >
                <RemoveIcon />
              </IconButton>

              <Slider
                value={currentZoom}
                min={MIN_ZOOM}
                max={MAX_ZOOM}
                onChange={(e, value) => {
                  setCurrentZoom(value);
                  setZoomTrigger((prev) => !prev);
                }}
                onChangeCommitted={(e, value) => {
                  trackAction("zoom slider");
                }}
                valueLabelDisplay="auto"
              />

              <IconButton
                disableRipple
                sx={{ p: 0 }}
                onClick={() => {
                  setCurrentZoom(currentZoom + 1);
                  setZoomTrigger((prev) => !prev);
                  trackAction("zoom slider");
                }}
              >
                <AddIcon />
              </IconButton>
            </Stack>
          </Grid>
        )}
      </Grid>

      {!mediumScreenAndUp && (
        <Grid
          id="bottom-tool-bar-2"
          container
          sx={{
            height: ({ customConfig }) => 50,
            borderTop: "0.5px solid #a6a6a6",
            px: {
              xs: 2,
              md: 10,
              lg: 14,
              xl: 20,
            },
            flexWrap: "nowrap",
            justifyContent: "space-between",
            position: "relative",
            zIndex: 100,
          }}
          gap={2}
        >
          <Grid item display={"flex"}>
            <IconButton
              disableRipple
              disabled={imageHandler.thumbnails.length < 1}
              onClick={() => {
                const isOpen = !isSliderOpened;

                if (isOpen) {
                  setIsOverlaySliderOpened(false);
                  setIsOverlayCalenderOpened(false);

                  trackAction("thumbnails slider");
                }

                setisSliderOpened(isOpen);
              }}
              sx={{
                color: ({ palette }) =>
                  isSliderOpened
                    ? `${palette.onSecondary.main} !important`
                    : `${alpha(palette.onSecondary.main, 0.8)} !important`,
                fontSize: "12px !important",
                p: 0,
              }}
            >
              {isOverlayEnabled && (
                <Typography sx={{ fontSize: 12 }}>A</Typography>
              )}
              <SliderIcon fontSize={"small"} />
            </IconButton>

            {isOverlayEnabled && (
              <IconButton
                disableRipple
                disabled={imageHandler.thumbnails.length < 1}
                onClick={() => {
                  const isOpen = !isOverlaySliderOpened;

                  if (isOpen) {
                    setisSliderOpened(false);
                    trackAction("thumbnails slider");
                  } else {
                    setIsCalenderOpened(false);
                  }

                  setIsOverlaySliderOpened(isOpen);
                }}
                sx={{
                  color: ({ palette }) =>
                    isOverlaySliderOpened
                      ? `${palette.onPrimary.main} !important`
                      : `${alpha(palette.onPrimary.main, 0.8)} !important`,
                  fontSize: "12px !important",
                  p: 0,
                  ml: 1,
                }}
              >
                <Typography sx={{ fontSize: 12 }}>B</Typography>
                <SliderIcon fontSize={"small"} />
              </IconButton>
            )}
          </Grid>

          <Grid
            item
            sx={{
              display: "flex",
              width: "100%",
              overflow: isOverlaySliderOpened ? "auto" : "visible",
            }}
          >
            {isOverlaySliderOpened ? (
              <Box
                onClick={(e) => {
                  e.preventDefault();

                  if (!isOverlayCalenderOpened) {
                    setIsOverlayCalenderOpened(true);
                    trackAction("date picker");
                  }
                }}
                sx={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  minWidth: "max-content",
                }}
              >
                <CalendarPicker
                  isCalenderOpened={isOverlayCalenderOpened}
                  setIsCalenderOpened={setIsOverlayCalenderOpened}
                  handleDateChange={(date) => {
                    handleDateChange(date, true);
                  }}
                  imageHandler={imageHandler}
                  currentDate={currentOverlayDate}
                  isOverlay={true}
                />

                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: {
                      xs: "space-around",
                      sm: "center",
                    },

                    background: ({ palette }) =>
                      !imageHandler.overlayThumbnails.length &&
                      !isOverlayLoading
                        ? alpha(palette.error.main, 0.6)
                        : alpha(
                            getContrastShade(palette.primary, "dark"),
                            isOverlaySliderOpened || isOverlayCalenderOpened
                              ? 0.6
                              : 0.2,
                          ),
                    transition: ({ transitions }) =>
                      transitions.create("background"),
                    height: "max-content",
                    px: 1,
                    py: 0.5,
                    borderRadius: 2,
                    position: "relative",
                    border: ({ palette }) =>
                      `1px solid ${alpha(palette.onSecondary.main, 0.2)}`,
                  }}
                >
                  <IconButton
                    color="inherit"
                    disableRipple
                    sx={{ p: 0 }}
                    disabled={prevOverlayDate < minDate || isOverlayLoading}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();

                      trackAction("previous day bottom");
                      handleDateChange(prevOverlayDate, true);
                    }}
                  >
                    <SkipPreviousIcon sx={{ fontSize: 22 }} />
                  </IconButton>

                  <IconButton
                    size="small"
                    disableRipple
                    sx={{ p: 0 }}
                    disabled={
                      !imageHandler ||
                      imageHandler.overlayThumbnails.length === 0 ||
                      overlayThumbnailIndex ===
                        imageHandler.overlayThumbnails.length - 1
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();

                      loadOverlayThumbnail(overlayThumbnailIndex + 1);

                      trackAction("previous image bottom");
                    }}
                  >
                    <NavigateBeforeIcon sx={{ fontSize: 22, ml: -0.5 }} />
                  </IconButton>

                  <Typography
                    variant="caption"
                    lineHeight={1}
                    sx={{
                      maxWidth: "200px",
                      whiteSpace: "noWrap",
                      mx: 0.2,

                      color: ({ palette }) =>
                        isOverlayLoading
                          ? `${alpha(palette.onPrimary.main, 0.4)} !important`
                          : isOverlayCalenderOpened
                          ? `${alpha(palette.onPrimary.main, 1)} !important`
                          : "inherit",
                      fontWeight: isOverlayCalenderOpened
                        ? "bold !important"
                        : "normal  !important",

                      ":hover": {
                        color: ({ palette }) =>
                          `${alpha(palette.onPrimary.main, 1)} !important`,
                      },
                    }}
                  >
                    {moment.unix(currentOverlayDate).format("ddd D")}
                    <sup>
                      {moment
                        .unix(currentOverlayDate)
                        .format("Do")
                        .replace(/\d+/, "")}
                    </sup>
                    {moment.unix(currentOverlayDate).format(" MMMM YYYY")}
                    {moment.unix(currentOverlayDate).format(" HH:mm")}
                  </Typography>

                  <IconButton
                    size="small"
                    color="inherit"
                    disableRipple
                    sx={{ p: 0 }}
                    disabled={
                      !imageHandler ||
                      imageHandler.overlayThumbnails.length === 0 ||
                      overlayThumbnailIndex === 0
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();

                      loadOverlayThumbnail(overlayThumbnailIndex - 1);

                      trackAction("next image bottom");
                    }}
                  >
                    <NavigateNextIcon sx={{ fontSize: 22, mr: -0.5 }} />
                  </IconButton>

                  <IconButton
                    color="inherit"
                    disableRipple
                    sx={{ p: 0 }}
                    disabled={nextOverlayDate > maxDate || isOverlayLoading}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();

                      handleDateChange(nextOverlayDate, true);

                      trackAction("next day bottom");
                    }}
                  >
                    <SkipNextIcon sx={{ fontSize: 22 }} />
                  </IconButton>
                </Box>
              </Box>
            ) : (
              <Stack
                spacing={2}
                direction="row"
                alignItems="center"
                width={"100%"}
                sx={{
                  pl: {
                    xs: 0,
                    sm: 2,
                    md: 10,
                    lg: 16,
                  },
                }}
              >
                <IconButton
                  disableRipple
                  sx={{ p: 0 }}
                  size="small"
                  onClick={() => {
                    setCurrentZoom(currentZoom - 1);
                    setZoomTrigger((prev) => !prev);
                    trackAction("zoom slider");
                  }}
                >
                  <RemoveIcon fontSize="small" />
                </IconButton>

                <Slider
                  size="small"
                  value={currentZoom}
                  min={MIN_ZOOM}
                  max={MAX_ZOOM}
                  onChange={(e, value) => {
                    setCurrentZoom(value);
                    setZoomTrigger((prev) => !prev);
                  }}
                  onChangeCommitted={(e, value) => {
                    trackAction("zoom slider");
                  }}
                  valueLabelDisplay="auto"
                />

                <IconButton
                  disableRipple
                  sx={{ p: 0 }}
                  size="small"
                  onClick={() => {
                    setCurrentZoom(currentZoom + 1);
                    setZoomTrigger((prev) => !prev);
                    trackAction("zoom slider");
                  }}
                >
                  <AddIcon fontSize="small" />
                </IconButton>
              </Stack>
            )}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

const ImageViewerWindow = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [imageHandler, setImageHandler] = useState<ImageHandlerV2 | null>(null);
  const [currentDate, setCurrentDate] = useState<UnixEpoch>(moment().unix());
  const [currentOverlayDate, setCurrentOverlayDate] = useState<UnixEpoch>(
    moment().unix(),
  );
  const [currentZoom, setCurrentZoom] = useState<number>(1);
  const [zoomTrigger, setZoomTrigger] = useState(false);

  const [currentThumbnailIndex, setCurrentThumbnailIndex] =
    useState<number>(-1);
  const [overlayThumbnailIndex, setOverlayThumbnailIndex] =
    useState<number>(-1);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isOverlayLoading, setIsOverlayLoading] = useState<boolean>(false);

  const [isOverlayEnabled, setIsOverlayEnabled] = useState<boolean>(false);
  const [isCommentsOpened, setIsCommentsOpened] = useState<boolean>(false);
  const [isTagsOpened, setIsTagsOpened] = useState<boolean>(false);
  const [isInfoOpened, setIsInfoOpened] = useState<boolean>(false);
  const [isWeatherInfoOpened, setIsWeatherInfoOpened] =
    useState<boolean>(false);

  const [downloadMenuAnchorEl, setDownloadMenuAnchorEl] =
    useState<null | HTMLElement>(null);

  const [commentList, setCommentList] = useState<Comment[]>([]);
  const [tagList, setTagList] = useState<string[]>([]);
  const [isFavorited, setIsFavorited] = useState(false);
  const [isKeyPressAllowed, setIsKeyPressAllowed] = useState(true);

  const [selectedImage, setSelectedImage] = useAtom(selectedImageState);

  const isDevice = !!location.pathname.match("/devices/");

  const { isBlocked, isAccessable, isSiteAccessable, isAdmin } = useAccess();

  const [componentCahces, setComponentCahces] = useAtom(
    imageViewerComponentCahcesState,
  );
  const [gridComponentCahces] = useAtom(gridComponentCahcesState);

  const { trackAction, trackImage } = useImageViewerTracker(
    imageHandler,
    "main",
    true,
  );

  const compCachesRef = useRef<any>(null);
  const firstRender = useRef(true);
  const skipEffects = useRef({
    imageHandler: false,
  });

  useEffect(() => {
    compCachesRef.current = {
      imageHandler,
      currentThumbnailIndex,
      currentDate,
      currentZoom,
    };
  });

  useEffect(() => {
    initViewer();

    return () => {
      setSelectedImage(null);
      setComponentCahces((caches) => {
        return {
          ...caches,
          [params.id as string]: _.cloneDeep(compCachesRef.current),
        };
      });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  useEffect(() => {
    if (currentThumbnailIndex >= 0 && imageHandler) {
      const thumbnail = imageHandler.thumbnails[currentThumbnailIndex];

      if (thumbnail) {
        trackImage(thumbnail.image);
      }
    }

    if (overlayThumbnailIndex >= 0 && imageHandler) {
      const overlayThumbnail = imageHandler.thumbnails[overlayThumbnailIndex];

      if (overlayThumbnail) {
        trackImage(overlayThumbnail.image);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentThumbnailIndex, overlayThumbnailIndex, imageHandler]);

  const initThumbnailFromCache = (imageHandler, thumbnailIndex) => {
    if (imageHandler) {
      const currentThumbnail = imageHandler.thumbnails[thumbnailIndex];

      if (currentThumbnail) {
        setCurrentDate(
          moment(imageHandler.getImageTime(currentThumbnail.image)).unix(),
        );
        setIsFavorited(imageHandler.getIsImageFavourited(currentThumbnail));
        setCurrentThumbnailIndex(thumbnailIndex);
        getComments(currentThumbnail, imageHandler);
        getTags(currentThumbnail, imageHandler);
      }
    }
  };

  const initViewer = async () => {
    const caches = componentCahces[params.id as string];
    const gridCahes = gridComponentCahces[params.id as string];

    // use caches from grid page
    if (selectedImage && gridCahes) {
      const imageHandler = gridCahes.imageHandler;

      const index = imageHandler.thumbnails.findIndex((thumbnail) => {
        return (
          imageHandler.getThumbnailImageId(thumbnail) ===
          imageHandler.getImageId(selectedImage)
        );
      });

      setImageHandler(imageHandler);

      initThumbnailFromCache(imageHandler, index);

      setIsLoaded(true);
      setIsLoading(false);

      skipEffects.current.imageHandler = true;
    } else if (caches) {
      // use caches if exist
      const imageHandler = caches.imageHandler;

      // this is from weather page
      if (selectedImage) {
        const index = imageHandler.thumbnails.findIndex((thumbnail) => {
          return (
            imageHandler.getThumbnailImageId(thumbnail) ===
            imageHandler.getImageId(selectedImage)
          );
        });

        setImageHandler(imageHandler);

        initThumbnailFromCache(imageHandler, index);
      } else {
        setCurrentZoom(caches.currentZoom);
        setCurrentThumbnailIndex(caches.currentThumbnailIndex);
        setCurrentDate(caches.currentDate);
        setImageHandler(imageHandler);

        initThumbnailFromCache(imageHandler, caches.currentThumbnailIndex);
      }

      setIsLoaded(true);
      setIsLoading(false);

      skipEffects.current.imageHandler = true;
    } else {
      // init normally

      setIsLoading(true);
      setIsLoaded(false);
      setCurrentThumbnailIndex(-1);
      setCommentList([]);
      setTagList([]);
      setIsFavorited(false);
      setIsOverlayEnabled(false);

      const getData = async () =>
        isDevice
          ? await getFirebaseController().Device.getDevice(params.id)
          : await getFirebaseController().Gallery.getGallery(params.id);

      await getData()
        .then(async (currentDevice) => {
          if (!currentDevice) {
            throw new Error(`${isDevice ? "Divice" : "Gallery"} not found.`);
          }

          if (
            (isDevice && !isAdmin) ||
            (!isDevice &&
              !isAdmin &&
              !isSiteAccessable((currentDevice as GalleryV2).jobSite))
          ) {
            navigate("/");
            return;
          }

          const imageHandler = new ImageHandlerV2(currentDevice, isDevice);

          setImageHandler(imageHandler);
        })
        .catch((err) => {
          console.error(err);
          navigate("../");

          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!firstRender.current && imageHandler) {
      if (skipEffects.current.imageHandler) {
        skipEffects.current.imageHandler = false;

        return;
      }

      setIsLoading(true);

      imageHandler
        .getImages()
        .then(async () => {
          if (selectedImage) {
            const imageDate = imageHandler.getImageTime(selectedImage);

            const startDate = moment(imageDate).startOf("days").unix();
            const endDate = moment(imageDate).endOf("days").unix();

            await imageHandler.filter(startDate, endDate);

            const index = imageHandler.thumbnails.findIndex((thumbnail) => {
              return (
                imageHandler.getThumbnailImageId(thumbnail) ===
                imageHandler.getImageId(selectedImage)
              );
            });
            setCurrentThumbnailIndex(index);
            getThumbnail(index);
          } else {
            const index = 0;

            getThumbnail(index);
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageHandler]);

  const handleKeyPress = useCallback(
    (e) => {
      if (isKeyPressAllowed) {
        switch (e.key) {
          case "ArrowLeft": {
            if (
              imageHandler &&
              imageHandler.thumbnails.length > 0 &&
              currentThumbnailIndex !== imageHandler.thumbnails.length - 1
            ) {
              getThumbnail(currentThumbnailIndex + 1);
            }
            break;
          }

          case "ArrowRight": {
            if (
              imageHandler &&
              imageHandler.thumbnails.length > 0 &&
              currentThumbnailIndex !== 0
            ) {
              getThumbnail(currentThumbnailIndex - 1);
            }

            break;
          }

          case "ArrowUp": {
            if (imageHandler) {
              const minDate = moment
                .unix(imageHandler.dateRange[0])
                .startOf("days")
                .unix();

              const prevDate = moment
                .unix(currentDate)
                .subtract(1, "days")
                .startOf("days")
                .unix();

              if (prevDate > minDate) {
                handleDateChange(prevDate);
              }
            }

            break;
          }

          case "ArrowDown": {
            if (imageHandler) {
              const maxDate = moment
                .unix(imageHandler.dateRange[1])
                .endOf("days")
                .unix();
              const nextDate = moment
                .unix(currentDate)
                .add(1, "days")
                .startOf("days")
                .unix();

              if (nextDate < maxDate) {
                handleDateChange(nextDate);
              }
            }

            break;
          }

          default:
            break;
        }
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [imageHandler, currentThumbnailIndex, currentDate, isKeyPressAllowed],
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };

    // tslint-disable-next-line
  }, [handleKeyPress]);

  // this must be place on last
  useEffect(() => {
    firstRender.current = false;
  }, []);

  const getThumbnail = async (thumbnailIndex: number, date?: number) => {
    setIsLoaded(false);
    setIsLoading(true);

    if (imageHandler) {
      const thumbnail = imageHandler.thumbnails[thumbnailIndex];

      if (thumbnail) {
        setIsLoading(true);
        await imageHandler.getImageExtras(thumbnail);

        const currentImage = await imageHandler.findThumbnail(thumbnail);

        if (currentImage.event) {
          await currentImage.event;
        }

        setIsLoading(false);

        setCurrentDate(
          moment(imageHandler.getImageTime(thumbnail.image)).unix(),
        );

        setIsFavorited(imageHandler.getIsImageFavourited(thumbnail));

        setCurrentThumbnailIndex(thumbnailIndex);
        getComments(thumbnail);
        getTags(thumbnail);
      } else {
        setIsLoaded(true);
        setIsLoading(false);
        setCurrentDate(date || moment().unix());
        setCurrentThumbnailIndex(0);
      }
    }
  };

  const loadOverlayThumbnail = async (
    thumbnailIndex: number,
    date?: number,
  ) => {
    setIsOverlayLoading(true);

    if (imageHandler) {
      const thumbnail = imageHandler.overlayThumbnails[thumbnailIndex];

      if (thumbnail) {
        const currentImage = await imageHandler.findThumbnail(thumbnail);

        if (currentImage.event) {
          await currentImage.event;
        }

        setIsOverlayLoading(false);
        setCurrentOverlayDate(
          moment(imageHandler.getImageTime(thumbnail.image)).unix(),
        );

        setOverlayThumbnailIndex(thumbnailIndex);
      } else {
        setIsOverlayLoading(false);
        setCurrentThumbnailIndex(0);
        setCurrentOverlayDate(date || moment().unix());
      }
    }
  };

  const handleDateChange = async (
    newDate: UnixEpoch,
    isOverlay = false,
    isNavigate = true,
  ) => {
    setIsLoading(true);

    if (!isOverlay) {
      setIsLoaded(false);
    }

    if (imageHandler) {
      await imageHandler.filter(
        newDate,
        moment.unix(newDate).endOf("days").unix(),
        undefined,
        undefined,
        null,
        isOverlay,
      );

      let index;

      const currentTime = moment.unix(currentDate).format("HH:mm");

      const matchedTimeIndex = imageHandler.thumbnails.findIndex(
        (thumbnail) => {
          const timestamp = imageHandler.getImageTime(thumbnail.image);

          return moment(timestamp).format("HH:mm") === currentTime;
        },
      );

      // try select the image that has same time with current
      if (matchedTimeIndex !== -1) {
        index = matchedTimeIndex;
      } else {
        // shows the latest if going to previous date, shows earliest if going to next day

        const usedThumbnails = isOverlay
          ? imageHandler!.overlayThumbnails
          : imageHandler!.thumbnails;
        const isPrev = newDate < currentDate;

        index = isNavigate ? (isPrev ? 0 : usedThumbnails.length - 1) : 0;
      }

      if (isOverlay) {
        await loadOverlayThumbnail(index, newDate);
      } else {
        await getThumbnail(index, newDate);
      }
    }
  };

  const getCurrentThumbnail = useMemo(() => {
    return () => imageHandler?.thumbnails?.[currentThumbnailIndex];
  }, [currentThumbnailIndex, imageHandler]);

  const getOverlayThumbnail = useMemo(() => {
    return () => imageHandler?.overlayThumbnails?.[overlayThumbnailIndex];
  }, [overlayThumbnailIndex, imageHandler]);

  const getComments = async (thumbnail, useImageHandler = imageHandler) => {
    if (useImageHandler) {
      const comments = await useImageHandler.getImageComments(thumbnail);

      setCommentList(comments);
    }
  };

  const getTags = async (thumbnail, useImageHandler = imageHandler) => {
    if (useImageHandler) {
      const tags = await useImageHandler.getImageTags(thumbnail);

      setTagList(tags);
    }
  };

  const buttons = [
    {
      name: "favorite",
      description: "Favorite",
      icon: (
        <StarIcon
          sx={{
            color: ({ palette }) =>
              isFavorited ? palette.warning.dark : "inherit",
          }}
        />
      ),
      isDisabled: !getCurrentThumbnail(),
      isBlocked: !isAccessable(["0", "3"]),
      onClick: async () => {
        const thumbnail = getCurrentThumbnail();

        if (thumbnail) {
          if (!isFavorited) {
            trackAction("favorite");
          }

          await imageHandler!
            .updateImageFavorited(thumbnail, !isFavorited)
            .then((imageExtras) => {
              if (imageExtras) {
                setIsFavorited(imageExtras.favorited);
              }
            })
            .catch(() => {});
        }
      },
    },
    {
      name: "comments",
      description: "Comments",
      icon: (
        <Badge
          badgeContent={commentList.length}
          max={9}
          color="info"
          overlap="circular"
          sx={{
            ".MuiBadge-badge": {
              fontSize: 10,
              height: 16,
              minWidth: 16,
              width: 16,
            },
          }}
        >
          <ChatIcon />
        </Badge>
      ),
      isActive: isCommentsOpened,
      isDisabled: !getCurrentThumbnail(),
      isBlocked: !isAccessable(["0", "3"]),
      onClick: () => {
        if (!isCommentsOpened) {
          trackAction("comments");
        }

        setIsCommentsOpened(!isCommentsOpened);
      },
    },
    {
      name: "tags",
      description: "Tags",
      icon: (
        <Badge
          badgeContent={tagList.length}
          max={9}
          color="info"
          overlap="circular"
          sx={{
            ".MuiBadge-badge": {
              fontSize: 10,
              height: 16,
              minWidth: 16,
              width: 16,
            },
          }}
        >
          <LocalOfferIcon />
        </Badge>
      ),
      isActive: isTagsOpened,
      isDisabled: !getCurrentThumbnail(),
      isBlocked: !isAccessable(["0", "3"]),
      onClick: () => {
        if (!isTagsOpened) {
          trackAction("tags");
        }

        setIsTagsOpened(!isTagsOpened);
      },
      divider: true,
    },
    {
      name: "weather",
      description: "Weather Infomation",
      icon: <WeatherIcon />,
      isActive: isWeatherInfoOpened,
      isDisabled: !getCurrentThumbnail(),
      isBlocked: !isAccessable(["0", "3"]),
      onClick: () => {
        if (!isWeatherInfoOpened) {
          trackAction("weather info");
        }

        setIsWeatherInfoOpened(!isWeatherInfoOpened);
        setIsInfoOpened(false);
      },
    },
    {
      name: "info",
      description: "Image Infomation",
      icon: <InfoIcon />,
      isActive: isInfoOpened,
      isDisabled: !getCurrentThumbnail(),
      isBlocked: !isAdmin,
      onClick: () => {
        if (!isInfoOpened) {
          trackAction("image info");
        }

        setIsInfoOpened(!isInfoOpened);
        setIsWeatherInfoOpened(false);
      },
      divider: true,
    },
    {
      name: "download",
      description: "Download",
      icon: <DownloadIcon />,
      isDisabled: !getCurrentThumbnail(),
      isBlocked: !isAccessable(["0", "3"]),
      onClick: async (e) => {
        setDownloadMenuAnchorEl(e.currentTarget);
      },
    },
    {
      name: "compare",
      description: "Compare Image",
      icon: <CompareIcon />,
      isActive: isOverlayEnabled,
      isDisabled: !getCurrentThumbnail(),
      onClick: () => {
        if (isOverlayEnabled) {
          setIsOverlayEnabled(!isOverlayEnabled);
        } else {
          trackAction("compare");

          const thumbnail = getCurrentThumbnail();

          if (imageHandler && thumbnail) {
            if (overlayThumbnailIndex === -1) {
              imageHandler.initOverlay();

              setOverlayThumbnailIndex(currentThumbnailIndex);
              setCurrentOverlayDate(currentDate);
              setIsOverlayEnabled(!isOverlayEnabled);
            } else {
              setIsOverlayEnabled(!isOverlayEnabled);
            }
          }
        }
      },
    },
    {
      name: "video",
      description: "Timelapse",
      icon: <VideocamIcon />,
      isDisabled: !getCurrentThumbnail(),
      onClick: () => {
        trackAction("timelapse player");

        navigate(`../${params.id}/timelapse-player`);
      },
      isBlocked: !isAccessable(["0", "3"]),
      divider: true,
    },
    {
      name: "grid",
      description: "All Images",
      icon: <AppsIcon />,
      onClick: () => {
        trackAction("all images");

        navigate("./list");
      },
    },
    {
      name: "settings",
      description: "Gallery settings",
      icon: <SettingsIcon />,
      onClick: () => {
        trackAction("settings");

        navigate(`../${params.id}`);
      },
      isBlocked: !isAdmin,
    },
  ];

  const smallScreenAndUp = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("sm"),
  );

  const mediumScreenAndUp = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("md"),
  );

  const bindSwipe = useDrag(
    ({ event, swipe, velocity, last }) => {
      event.preventDefault();
      event.stopPropagation();

      const swipeX = swipe[0];

      if (last && swipeX < 0) {
        // swiped left and change to next photo

        if (
          imageHandler &&
          imageHandler.thumbnails.length !== 0 &&
          currentThumbnailIndex !== 0
        ) {
          getThumbnail(currentThumbnailIndex - 1);
        }
      } else if (last && swipeX > 0) {
        // swiped right and change to prev photo

        if (
          imageHandler &&
          imageHandler.thumbnails.length !== 0 &&
          currentThumbnailIndex !== imageHandler.thumbnails.length - 1
        ) {
          getThumbnail(currentThumbnailIndex + 1);
        }
      }
    },
    {
      filterTaps: true,
    },
  );

  return (
    <Box
      sx={({ palette }) => ({
        minWidth: "100vw",
        minHeight: "100svh",
        height: "100dvh",
        backgroundColor: palette.secondary.main,
        color: alpha(palette.onSecondary.main, 1),
        position: "fixed",
        overflow: "hidden",

        ".MuiIconButton-root": {
          color: alpha(palette.onSecondary.main, 0.8),
          transition: ({ transitions }) => transitions.create("all"),

          ":hover": {
            color: alpha(palette.onSecondary.main, 1),
          },

          "&.Mui-disabled": {
            color: alpha(palette.onSecondary.main, 0.4),
          },
        },
      })}
    >
      <TopToolBar
        buttons={buttons}
        imageHandler={imageHandler}
        tracker={trackAction}
      />

      <CommentsSection
        isCommentsOpened={isCommentsOpened}
        setIsCommentsOpened={setIsCommentsOpened}
        setIsKeyPressAllowed={setIsKeyPressAllowed}
        imageHandler={imageHandler}
        currentThumbnail={getCurrentThumbnail()}
        handleClose={() => setIsCommentsOpened(!isCommentsOpened)}
        commentList={commentList}
        setCommentList={setCommentList}
      />

      <Box
        id="content-container"
        sx={({ customConfig }) => ({
          pt: customConfig.imageViewerNavbarHeight,
          pb: {
            xs: `calc(${customConfig.imageViewerNavbarHeight}* 2)`,
            md: customConfig.imageViewerNavbarHeight,
          },

          height: "100svh",
          width: "100vw",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        })}
      >
        <Box
          sx={{
            position: "absolute",
            zIndex: 1,
            visibility: !isLoaded ? "visible" : "hidden",
          }}
        >
          <LDSRingLoader />
        </Box>

        <Box
          id="viewer-container"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "100%",
            width: "100%",
            flexWrap: "nowrap",
            position: "relative",
            px: { xs: 0, sm: 1 },
          }}
        >
          <TagsSection
            isTagsOpened={isTagsOpened}
            setIsTagsOpened={setIsTagsOpened}
            setIsKeyPressAllowed={setIsKeyPressAllowed}
            tagList={tagList}
            setTagList={setTagList}
            thumbnail={getCurrentThumbnail()}
            imageHandler={imageHandler}
          />

          <InfoSection
            isInfoOpened={isInfoOpened}
            thumbnail={getCurrentThumbnail()}
            imageHandler={imageHandler}
          />

          <WeatherInfoSection
            isWeatherInfoOpened={isWeatherInfoOpened}
            thumbnail={getCurrentThumbnail()}
            imageHandler={imageHandler}
          />

          <DownloadMenuSection
            anchorEl={downloadMenuAnchorEl}
            handleClose={() => {
              setDownloadMenuAnchorEl(null);
            }}
            thumbnail={getCurrentThumbnail()}
            imageHandler={imageHandler}
            trackAction={trackAction}
          />

          {smallScreenAndUp && (
            <Box
              sx={{
                pl: 1,
                zIndex: 1,
                position: "absolute",
                top: "50%",
                left: "0%",
                transform: "translateY(-50%)",
              }}
            >
              <IconButton
                color="inherit"
                onClick={() => {
                  getThumbnail(currentThumbnailIndex + 1);
                  trackAction("previous image");
                }}
                disabled={
                  !imageHandler ||
                  imageHandler.thumbnails.length === 0 ||
                  currentThumbnailIndex === imageHandler.thumbnails.length - 1
                }
              >
                <ArrowBackIosNewIcon />
              </IconButton>
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              width: "100%",
              zIndex: 0,
              visibility: isLoaded ? "visible" : "hidden",
              touchAction: "none",
            }}
            {...bindSwipe()}
          >
            {getCurrentThumbnail() ? (
              <HighResImageV2
                imageHandler={imageHandler as ImageHandlerV2}
                thumbnail={getCurrentThumbnail()}
                isOverlayEnabled={isOverlayEnabled}
                overlayThumbnail={getOverlayThumbnail()}
                onZoomEnd={(zoom) => {
                  setCurrentZoom(zoom);
                }}
                zoom={currentZoom}
                zoomTrigger={zoomTrigger}
                onLoaded={() => setIsLoaded(true)}
                isOverlayLoading={isOverlayLoading}
              />
            ) : (
              <Typography variant="caption"> No image found</Typography>
            )}
          </Box>

          {smallScreenAndUp && (
            <Box
              sx={{
                pr: 1,
                zIndex: 1,
                position: "absolute",
                top: "50%",
                right: "0%",
                transform: "translateY(-50%)",
              }}
            >
              <IconButton
                color="inherit"
                onClick={() => {
                  trackAction("next image");
                  getThumbnail(currentThumbnailIndex - 1);
                }}
                disabled={
                  !imageHandler ||
                  imageHandler.thumbnails.length === 0 ||
                  currentThumbnailIndex === 0
                }
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </Box>
          )}
        </Box>
      </Box>

      {imageHandler && (
        <BottomToolBar
          currentDate={currentDate}
          currentOverlayDate={currentOverlayDate}
          handleDateChange={handleDateChange}
          currentThumbnailIndex={currentThumbnailIndex}
          overlayThumbnailIndex={overlayThumbnailIndex}
          getThumbnail={getThumbnail}
          loadOverlayThumbnail={loadOverlayThumbnail}
          imageHandler={imageHandler}
          setCurrentZoom={setCurrentZoom}
          currentZoom={currentZoom}
          isOverlayEnabled={isOverlayEnabled}
          isOverlayLoading={isOverlayLoading}
          setZoomTrigger={setZoomTrigger}
        />
      )}
    </Box>
  );
};

export default ImageViewerWindow;
