import { RefObject, useEffect, useMemo, useRef, useState } from "react";

import { Link, useNavigate } from "react-router-dom";

import { useAtomValue } from "jotai";
import { searchState } from "states/navbar";
import { currentUserState } from "states/auth";

import {
  Box,
  Chip,
  Link as MuiLink,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  alpha,
  lighten,
} from "@mui/material";

import { Client, JobSite, User, UserRole } from "database/DataTypes";
import { ROLE_ACCESS_LEVEL_MAPPER } from "database/DataDefaultValues";

import DashboardContainer from "components/Dashboard/DashboardContainer";
import DashboardHeader, {
  SortType,
} from "components/Dashboard/DashboardHeader";

import { Column } from "components/Dashboard/SettingsTable";

import _ from "lodash";
import { useDashboardTracker } from "hooks/eventTracker/useDashboardTracker";
import { getFirebaseController } from "database/FirebaseController";
import { clientListCacheState, jobSitesListCacheState } from "states/caches";
import DataChips from "components/Dashboard/DataChips";
import DashboardListTable from "components/Dashboard/DashboardListTable";
import { orderByIgnoreCase } from "utils/display";
import { getContrastShade } from "theme/reliveItTheme";

const SORT_OPTIONS = ["Name"];

const ListView = ({
  displayUserList,
  userRolesLookup,
  jobSitesLookUp,
  isAdminLookup,
  headerRef,
  isLoading,
}: {
  displayUserList: User[];
  userRolesLookup: { [key: number]: UserRole[] };
  jobSitesLookUp: { [key: number]: JobSite };
  isAdminLookup: { [key: number]: boolean };
  headerRef: RefObject<HTMLDivElement>;
  isLoading: boolean;
}) => {
  const navigate = useNavigate();

  const currentUser = useAtomValue(currentUserState);

  const columns: Column[] = [
    { id: "username", label: "Name", minWidth: "30%" },
    { id: "accessLevel", label: "Access Level", width: "30%" },
    { id: "accessableJobSites", label: "Accessible Sites", width: "40%" },
  ];

  const rows = displayUserList.map((user) => {
    const isAdmin = isAdminLookup[user.id as number];
    const userRoles = userRolesLookup[user.id as number];
    const userRole = userRoles[0];
    const activeJobSites = userRole.accessableJobSites.reduce<JobSite[]>(
      (sites, siteId) => {
        const site = jobSitesLookUp[siteId as number];

        if (site && !site.archived) {
          sites.push(site);
        }

        return sites;
      },
      [],
    );

    return {
      id: user.id as number,
      username: user.username || "-",
      accessLevel: (
        <Box>
          {userRole && (
            <Box key={userRole.id}>
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: isAdmin ? "bold" : "normal",
                }}
              >
                {`${ROLE_ACCESS_LEVEL_MAPPER[userRole.accessLevel]} [${
                  _clientCaches[userRole.associatedClient as number]?.name ||
                  "-"
                }]`}
              </Typography>
            </Box>
          )}
        </Box>
      ),

      accessableJobSites: isAdmin ? (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: "bold",
          }}
        >
          All
        </Typography>
      ) : (
        <Box>
          <DataChips<JobSite>
            items={activeJobSites}
            labelFormat={(site) => {
              return site.name || "";
            }}
            itemLink={(site) => `/job-sites/${site.id}`}
            limit={3}
          />
        </Box>
      ),
    };
  });

  return (
    <Box sx={{ width: "100%" }}>
      <DashboardListTable
        rows={rows}
        columns={columns}
        isLoading={isLoading}
        noDataText="No users found."
        rowOnClick={(row) => {
          navigate(`/users/${row.id}`);
        }}
        rowProps={(row, rowIndex) => {
          const user = displayUserList[rowIndex];
          const isUser = user.userId === currentUser?.userId;

          return {
            sx: {
              cursor: "pointer",

              backgroundColor: ({ palette }) =>
                isUser
                  ? `${alpha(palette.primary.main, 0.4)} !important`
                  : palette.surface.main,

              "&:nth-of-type(even)": {
                backgroundColor: ({ palette }) =>
                  alpha(getContrastShade(palette.surface, "dark"), 0.2),
              },

              ":hover": {
                backgroundColor: ({ palette }) =>
                  isUser
                    ? `${alpha(palette.primary.main, 0.6)} !important`
                    : `${alpha(
                        getContrastShade(palette.surface, "dark"),
                        0.6,
                      )} !important`,
              },
            },
          };
        }}
      />
    </Box>
  );
};

const _clientCaches: { [key: number]: Client } = {};

const UserIndexWindow = () => {
  const [displayUserList, setDisplayUserList] = useState<User[]>([]);
  const [userList, setUserList] = useState<User[]>([]);
  const [userRolesLookup, setUserRolesLookup] = useState<{
    [key: number]: UserRole[];
  }>({});
  const [isAdminLookup, setIsAdminLookup] = useState<{
    [key: number]: boolean;
  }>({});
  const [currentSortType, setCurrentSortType] = useState<SortType>({
    type: SORT_OPTIONS[0],
    isAsc: true,
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const headerRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();
  const search = useAtomValue(searchState);

  const { trackAction } = useDashboardTracker("users", true);

  const jobSitesListCache = useAtomValue(jobSitesListCacheState);
  const clientListCache = useAtomValue(clientListCacheState);

  const jobSitesLookUp: { [jobSiteId: number]: JobSite } = useMemo(() => {
    return _.keyBy(jobSitesListCache, "id");
  }, [jobSitesListCache]);

  const clientsLookup: { [clientId: number]: Client } = useMemo(() => {
    return _.keyBy(clientListCache, "id");
  }, [clientListCache]);

  useEffect(() => {
    handleSearch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.onSearch]);

  useEffect(() => {
    getUserList();
  }, []);

  const getUserList = async () => {
    setIsLoading(true);

    try {
      const firebaseController = getFirebaseController();

      // user roles by client -> fetch user
      const usersRoleList: UserRole[] =
        await firebaseController.User.getUserRoles();

      const userLists: User[] = [];
      const userRolesLookup = {};
      const isAdminLookup = {};

      const userRolePromises: Promise<any>[] = [];
      const clientPromises: Promise<any>[] = [];

      usersRoleList.forEach((role) => {
        const userId = role.associatedUser as number;
        isAdminLookup[userId] = role.accessLevel === "3";

        if (!userRolesLookup[userId]) {
          userRolePromises.push(
            firebaseController.User.getUser(role.associatedUser)
              .then((user) => {
                if (!userRolesLookup[userId]) {
                  userLists.push(user as User);
                  userRolesLookup[userId] = [];
                }

                userRolesLookup[userId].push(role);
              })
              .catch(() => {
                userRolesLookup[userId] = [];
              }),
          );

          if (!_clientCaches[role.associatedClient as number]) {
            clientPromises.push(
              firebaseController.Client.getClient(role.associatedClient).then(
                (client) => {
                  if (client) {
                    _clientCaches[client.id as number] = client as Client;
                  }
                },
              ),
            );
          }
        } else {
          userRolesLookup[userId].push(role);
        }
      });

      await Promise.all([...userRolePromises, ...clientPromises]).then(() => {
        const sortedList = orderByIgnoreCase(userLists, "email", "asc");

        setUserRolesLookup(userRolesLookup);
        setUserList(sortedList);
        setDisplayUserList(sortedList);
        setIsAdminLookup(isAdminLookup);
      });
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  const handleSearch = () => {
    let newUserList = _.cloneDeep(userList);

    if (search.value) {
      trackAction("search");

      newUserList = newUserList.filter((user) => {
        const userRole = userRolesLookup[user.id as number]?.[0];

        const searchFieldValues = [user.username, user.email];

        if (userRole) {
          const jobSiteNames = userRole.accessableJobSites.map((id) => {
            return jobSitesLookUp[id as number].name;
          });

          const client = clientsLookup[userRole.associatedClient as number];

          searchFieldValues.push(...jobSiteNames, client.name);
        }

        return searchFieldValues.some((value) => {
          return (
            value &&
            _.toLower(value.toString()).includes(_.toLower(search.value))
          );
        });
      });

      setDisplayUserList(newUserList);
    } else {
      setDisplayUserList(userList);
    }
  };

  const handleSort = (sortType: string) => {
    const isAsc =
      currentSortType.type === sortType ? !currentSortType.isAsc : false;
    const order = isAsc ? "asc" : "desc";

    setCurrentSortType({
      type: sortType,
      isAsc,
    });

    let newUserList = _.cloneDeep(displayUserList);

    switch (sortType) {
      case "Name":
        trackAction("sort name");

        newUserList = orderByIgnoreCase(newUserList, "username", order);

        break;

      default:
        break;
    }

    setDisplayUserList(newUserList);
  };

  return (
    <DashboardContainer NavbarProps={{ title: "User Dashboard" }}>
      <DashboardHeader
        sortOptions={SORT_OPTIONS}
        currentSortType={currentSortType}
        handleSort={handleSort}
        buttons={[
          {
            label: "+ Add New User",
            onClick: () => navigate("./new"),
          },
        ]}
      />

      <ListView
        displayUserList={displayUserList}
        headerRef={headerRef}
        userRolesLookup={userRolesLookup}
        jobSitesLookUp={jobSitesLookUp}
        isAdminLookup={isAdminLookup}
        isLoading={isLoading}
      />
    </DashboardContainer>
  );
};

export default UserIndexWindow;
